import { useState, useEffect } from 'react';
import Debug from "../services/debug.js";
import { useDebug } from '../hooks/useDebugContext';
import { useTranslation } from 'react-i18next';

const useBluetooth = (debugFlag) => {
  const { t, i18n } = useTranslation();

  const [isConnected, setIsConnected] = useState(false);
  const [isPreflightChecked, setIsPreflightChecked] = useState(false);
  const [bikeSerial, setBikeSerial] = useState(null);
  const [bikeCurrentError, setBikeCurrentError] = useState(null);
  const [device, setDevice] = useState(null);
  const [server, setServer] = useState(null);
  const [primaryService, setPrimaryService] = useState(null);
  const [OTAService, setOTAService] = useState(null);
  const [debugClearError, setDebugClearError] = useState(false);
  const [deviceInformationService, setDeviceInformationService] = useState(null);
  const [bleConnectionError, setBleConnectionError] = useState(false);

  const { debugBLE } = useDebug(); // Use debugBLE from DebugContext

  Debug.logger(3, 'debugBLE hook', debugBLE)

//variables
  const errorDescriptions = {
    0x00000000: t("No error"),
    0x00000001: t("Throttle stuck"),
    0x00000002: t("Under voltage"),
    0x00000004: t("Over voltage"),
    0x00000008: t("Under temperature"),
    0x00000010: t("Motor Over temperature"),
    0x00000020: t("Motor foldback temperature"),
    0x00000040: t("Motor NTC Disconnected/ low temperature"),
    0x00000080: t("Controller over temperature"),
    0x00000100: t("Controller foldback temperature"),
    0x00000200: t("Motor Hall error"),
    0x00000400: t("Motor Phase Error"),
    0x00000800: t("IoT Comm Error"),
    0x00001000: t("Dual Comm Error"),
    0x00002000: t("Over Current"),
    0x00004000: t("Battery Low"),
    0x00008000: t("PAS Sensor Error"),
    0x00010000: t("Controller Error"),
    0x00020000: t("Brake Error"),
    0x00040000: t("Screen Communication Error"),
    0x00080000: t("Torque Sensor Stuck Error"),
    0x00200000: t("Battery Error"),
    0x00400000: t("Powertrain Locked")
  };

  const cloudDriveErrorMapping = {
    1: '7', // Throttle stuck
    2: '4', // Under voltage
    4: '12', // Over voltage
    8: '8', // Under temperature
    16: '11', // Motor Over temperature
    32: '11', // Motor foldback temperature
    64: '14', // Motor NTC Disconnected/ low temperature
    128: '9', // Controller over temperature
    256: '9', // Controller foldback temperature
    512: '6', // Motor Hall error
    1024: '3', // Motor Phase Error
    2048: '10', // IoT Comm Error
    // 4096: '19', // Dual comm error. N/A on Clouddrive for now
    // 8192: '14', // Over Current. N/A on Clouddrive for now
    16384: '13', // Battery Low
    32768: '1', // PAS Sensor Error
    65536: '2', // Controller Anomaly
    131072: '5'  // Brake Error
  };

  const Protocol = {
    prefixEvionics: "EvionicsO",// "EvionicsO",
    prefixFTEX: "FTEXO",// "EvionicsO",
    genericAccess: {
      service: 0x1800,
      characteristics: {
        deviceName: 0x2A00,
        appearance: 0x2A01,
      }
    },
    deviceInformation: {
      service: 0x180A,
      characteristics: {
        manufacturer: 0x2A29,
        serialNumber: 0x1111,
        firmwareRevision: 0x2A26,
        hardwareRevision: 0x2A27,
      }
    },
    primaryService: {
      service: "e8012f0f-6630-4a43-80e6-5b3986e2f2d7",
      characteristics: {
        pas: 0x2F11,
        status: 0x2F12,
        error: "e8012f13-6630-4a43-80e6-5b3986e2f2d7",
        configPayload: 0x2F14,
      }
    },
    otaService: {
      service: "e8012ff0-6630-4a43-80e6-5b3986e2f2d7",
      characteristics: {
        otaCommand: "e8012ff1-6630-4a43-80e6-5b3986e2f2d7",
        otaStatus: "e8012ff2-6630-4a43-80e6-5b3986e2f2d7",
        dataValidation: "e8012ff3-6630-4a43-80e6-5b3986e2f2d7",
        dataTransfer: "e8012ff4-6630-4a43-80e6-5b3986e2f2d7",
      }
    }
  }

//helper functions for formating
const dataViewToAsciiString = (dataView) => {
    let asciiString = '';
    for (let i = 0; i < dataView.byteLength; i++) {
        // Convert each byte to hexadecimal
        Debug.logger(1, 'dataView.getUint8(i).toString(16)', dataView.getUint8(i).toString(16));
        const hexValue = dataView.getUint8(i).toString(16);
        // Convert the hexadecimal value to ASCII character
        Debug.logger(1, 'String.fromCharCode(parseInt(hexValue, 16))', String.fromCharCode(parseInt(hexValue, 16)))
        const asciiChar = String.fromCharCode(parseInt(hexValue, 16));
        asciiString += asciiChar;
    }
    return asciiString;
};

const readErrorCodeCharacteristic = (dataView) => {
    const byteLength = dataView.byteLength;
    if (byteLength !== 8 && byteLength !== 9) {
        throw new Error("Invalid data length for error code characteristic");
    }

    let errorCode, timestamp, dataType;
    
    if (byteLength === 8) {
        // Old protocol
        errorCode = dataView.getUint32(0, true); // true for little-endian
        timestamp = dataView.getUint32(4, true); // true for little-endian
    } else if (byteLength === 9) {
        // New protocol
        dataType = dataView.getUint8(0); // 1 byte for data type
        errorCode = dataView.getUint32(1, true); // 4 bytes for error code
        timestamp = dataView.getUint32(5, true); // 4 bytes for timestamp
    }

    const binaryString = errorCode.toString(2).padStart(32, '0');

    const currentErrors = [];
    Object.keys(errorDescriptions).forEach((key, index) => {
        if (binaryString[31 - index] === '1') {
            currentErrors.push({
                code: cloudDriveErrorMapping[key],
                description: errorDescriptions[key],
                timestamp: new Date(timestamp * 1000).toISOString(),
            });
        }
    });

    return currentErrors.length > 0 ? currentErrors : [{
        code: '0',
        description: 'No error',
        timestamp: new Date(timestamp * 1000).toISOString(),
    }];
};


const getDebugState = () => {
  return debugBLE;
}



  const formatFirmwareVersion = (dataView) => {
      // where MM is major, mm is minor, and pppp is patch part.
      const major = dataView.getUint8(0).toString().padStart(2, '0');
      const minor = dataView.getUint8(1).toString().padStart(2, '0');
      const patch = (dataView.getUint8(2) | (dataView.getUint8(3) << 8)).toString().padStart(4, '0');

      return `${major}.${minor}.${patch}`;
  };

//bluetooth connection methods
  const handleDisconnect = (setLoadingScreen) => {
    Debug.logger(1, 'Bluetooth connection has been terminated');
    setIsConnected(false);
    setIsPreflightChecked(false);
    setDevice(null);
    setServer(null);
    setBikeSerial(null);
    setPrimaryService(null);
    setDeviceInformationService(null);
    setDebugClearError(null);
    if (typeof setLoadingScreen === 'function') {
      setLoadingScreen(false);
    }
  };

  useEffect(() => {
    if (device) {
      device.addEventListener('gattserverdisconnected', handleDisconnect);
    }
    return () => {
      if (device) {
        device.removeEventListener('gattserverdisconnected', handleDisconnect);
      }
    };
  }, [device]);

const connectToDevice = async (setLoadingScreen, setBleConnectionError) => {
    await disconnectFromDevice();

    if (debugBLE) {
        setIsConnected(true);
        setBikeSerial("TESTBIKE001");
        setBikeCurrentError([{
            code: '4',
            description: 'Low Voltage',
            timestamp: new Date().toISOString(),
        }]);
        if (typeof setBleConnectionError === 'function') {
          setBleConnectionError(false);
        }
        if (typeof setLoadingScreen === 'function') {
          setLoadingScreen(false);
        }
        Debug.logger(3, 'In debug mode');
        return;
    }

    const bluetoothAvailability = await navigator.bluetooth.getAvailability();
    if (!bluetoothAvailability) {
        Debug.logger(3, 'Bluetooth is not available on this device or browser version');
        setBleConnectionError(true);
        if (typeof setLoadingScreen === 'function') {
          setLoadingScreen(false);
        }
        getDeviceInformation();
        return;
    }

    handleDisconnect();

    try {
        const options = {
            filters: [{ namePrefix: Protocol.prefixEvionics }, { namePrefix: Protocol.prefixFTEX }],
            optionalServices: [Protocol.deviceInformation.service, Protocol.primaryService.service, Protocol.otaService.service],
        };

        const bleDevice = await navigator.bluetooth.requestDevice(options);

        setDevice(bleDevice);

        Debug.logger(3, 'bleDevice', bleDevice);

        const bleServer = await bleDevice.gatt.connect();
        setServer(bleServer);
        Debug.logger(3, 'bleServer', bleServer);

        if (!bleDevice.gatt.connected) {
            throw new Error("Failed to connect to the GATT Server.");
        }

        // Trigger bonding (pairing) if necessary
        await bleDevice.gatt.connect(); // Ensure device is connected
        await bleServer.getPrimaryService(Protocol.deviceInformation.service); // Trigger the pairing request

        const primaryServiceforBLE = await bleServer.getPrimaryService(Protocol.primaryService.service);
        await setPrimaryService(primaryServiceforBLE);
        Debug.logger(3, 'Primary service set:', primaryServiceforBLE, primaryService);

        const otaServiceforBLE = await bleServer.getPrimaryService(Protocol.otaService.service);
        await setOTAService(otaServiceforBLE);
        Debug.logger(3, 'OTA service set:', otaServiceforBLE, OTAService);

        const deviceInfoService = await bleServer.getPrimaryService(Protocol.deviceInformation.service);
        setDeviceInformationService(deviceInfoService);

        const serial = await getDeviceInformation(bleDevice, deviceInfoService, setLoadingScreen, setBleConnectionError);
        setIsConnected(true);
        return serial;
    } catch (error) {
        Debug.logger(2, 'Error connecting to the Bluetooth device:', error);
        if (typeof setBleConnectionError === 'function') {
          setBleConnectionError(false);
        }
        if (typeof setLoadingScreen === 'function') {
          setLoadingScreen(false);
        }
        handleDisconnect(setLoadingScreen);
    }
};

  const disconnectFromDevice = () => {
    if (debugBLE) {
      handleDisconnect();
    }

    if (device?.gatt.connected) {
      device.gatt.disconnect();
      Debug.logger(2, 'Disconnected from Bike');
      handleDisconnect();
    }
  };

//enter pin
  const submitPin = async (pinNumber, primaryService) => {
    if (!pinNumber) {
        Debug.logger(1, 'Pin is Required');
        return;
    }
    if (!primaryService) {
        Debug.logger(1, 'Primary service not available');
        return;
    }

    try {
        const pinCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7';
        const pinCharacteristic = await primaryService.getCharacteristic(pinCharacteristicUuid);

        // Format the PIN number. PIN is 4 bytes, and one byte for Confirm/Set.
        // Assuming PIN is a string and needs to be converted into bytes.
        const pinData = new TextEncoder().encode(pinNumber);
        const confirmSetByte = new Uint8Array([0]); // Set to 0 for confirm, 1 for set new PIN.
        const pinPayload = new Uint8Array([...pinData, ...confirmSetByte]);
          Debug.logger(1, 'pinPayload', pinPayload, bikeSerial);
        // Write the PIN to the characteristic
        let res = await pinCharacteristic.writeValue(pinPayload);
        Debug.logger(1, 'PIN submitted successfully', res);
    } catch (error) {
        Debug.logger(1, 'Error submitting PIN:', error);
    }
  };

  const verifyPin = async (pin, bleService) => {
      if (!pin) {
          Debug.logger(1, 'Pin is Required');
          return;
      }
      if (!bleService) {
          Debug.logger(1, 'Primary service not available', primaryService, bleService);
          return false;
      }

      try {
          const pinCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7';
          const pinCharacteristic = await bleService.getCharacteristic(pinCharacteristicUuid);

          // Format the PIN number. PIN is 4 bytes, followed by the confirm byte (0).
          const pinArray = new Uint8Array([...pin.split('').map(char => char.charCodeAt(0)), 0]);
          
          await pinCharacteristic.writeValue(pinArray);
          Debug.logger(1, 'PIN submitted successfully', pinArray);

          // Assume if not disconnected, PIN is correct
          return true;
      } catch (error) {
          Debug.logger(1, 'Error verifying PIN:', error);
          return false;
      }
  };

  const parseCANResponse = (value) => {
      const format = value.getUint8(0);
      if (format === 'P'.charCodeAt(0)) {
          const responsePayload = new Uint8Array(value.buffer.slice(1));
          Debug.logger(1, 'Parsed CAN response:', responsePayload);
          return responsePayload;
      } else if (format === 'E'.charCodeAt(0)) {
          const errorPayload = new Uint8Array(value.buffer.slice(1));
          Debug.logger(1, 'Parsed Error response:', errorPayload);
          return errorPayload;
      } else {
          throw new Error('Invalid response format');
      }
  };

const startNotificationListener = async (bleService, notificationCallback) => {
    if (!notificationCallback) {
        console.error('A Callback is Required');
        return;
    }
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }
    // console.log('startNotificationListener', bleService);
    try {
        const dataCharacteristicUuid = 'e8012f13-6630-4a43-80e6-5b3986e2f2d7'; // Data characteristic UUID
        const dataCharacteristic = await bleService.getCharacteristic(dataCharacteristicUuid);
        // console.log('startNotificationListener', dataCharacteristic);
        if (!dataCharacteristic) {
            console.error('Data characteristic not found');
            notificationCallback(null, 'Data characteristic not found');
            return;
        }

        // Log characteristic properties
        // console.log('Data characteristic properties:', dataCharacteristic.properties);

        if (!dataCharacteristic.properties.notify) {
            console.error('Data characteristic does not support notifications');
            notificationCallback(null, 'Data characteristic does not support notifications');
            return;
        }

        // console.log('Data characteristic found:', dataCharacteristic);

        // Remove any existing event listener
        if (dataCharacteristic.oncharacteristicvaluechanged) {
            dataCharacteristic.removeEventListener('characteristicvaluechanged', dataCharacteristic.oncharacteristicvaluechanged);
            dataCharacteristic.oncharacteristicvaluechanged = null;
            // console.log('Existing event listener removed');
        }

        const notificationHandler = (event) => {
            const value = new Uint8Array(event.target.value.buffer);
            // console.log('Notification received:', value);
            if (notificationCallback) {
                notificationCallback(value);
            }
        };

        dataCharacteristic.addEventListener('characteristicvaluechanged', notificationHandler);
        dataCharacteristic.oncharacteristicvaluechanged = notificationHandler;

        // Increasing delay before starting notifications
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay

        await dataCharacteristic.startNotifications();
        // console.log('Notifications started successfully');
    } catch (error) {
        notificationCallback(null, error);
        console.error('Error starting notification listener:', error);
        if (error instanceof DOMException) {
            console.error('GATT operation failed with code:', error.code);
        }
    }
};

const stopNotificationListener = async (bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const dataCharacteristicUuid = 'e8012f13-6630-4a43-80e6-5b3986e2f2d7'; // Data characteristic UUID
        const dataCharacteristic = await bleService.getCharacteristic(dataCharacteristicUuid);

        // Check if there is an event listener and remove it
        if (dataCharacteristic.oncharacteristicvaluechanged) {
            dataCharacteristic.removeEventListener('characteristicvaluechanged', dataCharacteristic.oncharacteristicvaluechanged);
            dataCharacteristic.oncharacteristicvaluechanged = null;
            // console.log('Event listener removed');
        }

        await dataCharacteristic.stopNotifications();
        // console.log('Notifications stopped');
    } catch (error) {
        console.error('Error stopping notification listener:', error);
    }
};

const getSendPassthroughRW = async (bleService) => {
  if (!bleService) {
    console.error('Bluetooth service is not initialized');
    return null;
  }

  try {
    const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7';
    const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
    if (!passthroughCharacteristic) {
      console.error('Passthrough characteristic not found');
      return null;
    }

    const passthroughFunction = async (readWrite, pin, nodeId, index, subindex, data) => {
      // console.log('passthroughFunction', readWrite, pin, nodeId, index, subindex, data)
      if (!pin) {
        console.error('PIN is required for passthrough function');
        return;
      }

      try {
        const commandPayload = new Uint8Array(17);
        commandPayload.set([...pin.split('').map(char => char.charCodeAt(0))], 0);
        commandPayload[4] = 'P'.charCodeAt(0);
        commandPayload[5] = nodeId & 0xFF;
        commandPayload[6] = (nodeId >> 8) & 0xFF;
        commandPayload[7] = (nodeId >> 16) & 0xFF;
        commandPayload[8] = (nodeId >> 24) & 0xFF;
        commandPayload[10] = index & 0xFF;
        commandPayload[11] = (index >> 8) & 0xFF;
        commandPayload[12] = subindex;

        if (readWrite === 'read') {
          // console.log('passthroughFunction READ', readWrite, pin, nodeId, index, subindex, data);
          commandPayload[9] = 0x40;
        } else if (readWrite === 'write') {
          // console.log('passthroughFunction WRITE', readWrite, pin, nodeId, index, subindex, data);
          commandPayload[9] = 0x2F;
          commandPayload[13] = data & 0xFF;
          commandPayload[14] = (data >> 8) & 0xFF;
          commandPayload[15] = (data >> 16) & 0xFF;
          commandPayload[16] = (data >> 24) & 0xFF;
          // console.log('Constructed write command:', commandPayload);
        } else {
          console.error('Invalid readWrite value. Must be "read" or "write".');
          return;
        }

        await passthroughCharacteristic.writeValue(commandPayload);
      } catch (error) {
        console.error('Error in setup and communicate with characteristics:', error);
      }
    };

    return passthroughFunction;
  } catch (error) {
    console.error('Error retrieving characteristic:', error);
    return null;
  }
};


const writePassthroughCharacteristic = async (pin, nodeId, index, subindex, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID
        const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);

        // Construct the read command payload with exactly 17 bytes
        const readCommand = new Uint8Array(17);
        readCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
        readCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
        readCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
        readCommand[6] = (nodeId >> 8) & 0xFF;
        readCommand[7] = (nodeId >> 16) & 0xFF;
        readCommand[8] = (nodeId >> 24) & 0xFF;
        readCommand[9] = 0x40; // Payload Type: Command to read (1 byte)
        readCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
        readCommand[11] = (index >> 8) & 0xFF;
        readCommand[12] = subindex; // Sub Index (1 byte)
        // Remaining 4 bytes are zero-padded for the data payload

        // Log the constructed read command
        // console.log('Constructed read command:', readCommand);

        // Write the command to the characteristic and log the result
        const response = await passthroughCharacteristic.writeValue(readCommand);
    } catch (error) {
        console.error('Error writing passthrough characteristic:', error);
    }
};

const sendPassthroughRead = async (pin, nodeId, index, subindex, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID

        // Get passthrough characteristic
        const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
        if (!passthroughCharacteristic) {
            console.error('Passthrough characteristic not found');
            return;
        }

        // Construct the read command payload with exactly 17 bytes
        const readCommand = new Uint8Array(17);
        readCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
        readCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
        readCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
        readCommand[6] = (nodeId >> 8) & 0xFF;
        readCommand[7] = (nodeId >> 16) & 0xFF;
        readCommand[8] = (nodeId >> 24) & 0xFF;
        readCommand[9] = 0x40; // Payload Type: Command to read (1 byte)
        readCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
        readCommand[11] = (index >> 8) & 0xFF;
        readCommand[12] = subindex; // Sub Index (1 byte)
        // Remaining 4 bytes are zero-padded for the data payload

        // Log the constructed read command
        console.log('Constructed read command:', readCommand);

        // Write the command to the characteristic
        await passthroughCharacteristic.writeValue(readCommand);
        console.log('Write command sent successfully');
    } catch (error) {
        console.error('Error in setup and communicate with characteristics:', error);
    }
};

const sendPassthroughWrite = async (pin, nodeId, index, subindex, data, bleService, size) => {
    console.log('sendPassthroughWrite', pin, nodeId, index, subindex, data, bleService);
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID

        // Get passthrough characteristic
        const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
        if (!passthroughCharacteristic) {
            console.error('Passthrough characteristic not found');
            return;
        }

        // Ensure the data is a Uint8Array and matches the specified size
        let dataArray;
        if (typeof data === 'number') {
            dataArray = new Uint8Array(size);
            for (let i = 0; i < size; i++) {
                dataArray[i] = (data >> (8 * i)) & 0xFF;
            }
        } else if (data instanceof Uint8Array) {
            dataArray = data;
        }

        // Determine the payload type based on the size
        let payloadType = 0x2F;
        if (size == 2) {
                payloadType = 0x2B; // 2 bytes write
        }
        if (size == 3) {
                payloadType = 0x27; // 3 bytes write
        }
        if (size == 4) {
                payloadType = 0x23; // 4 bytes write
        }

        // Construct the write command payload with exactly 17 bytes
        const writeCommand = new Uint8Array(17);
        writeCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
        writeCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
        writeCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
        writeCommand[6] = (nodeId >> 8) & 0xFF;
        writeCommand[7] = (nodeId >> 16) & 0xFF;
        writeCommand[8] = (nodeId >> 24) & 0xFF;
        writeCommand[9] = payloadType; // Payload Type based on data length
        writeCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
        writeCommand[11] = (index >> 8) & 0xFF;
        writeCommand[12] = subindex; // Sub Index (1 byte)
        writeCommand.set(dataArray, 13); // Set the data bytes, with appropriate length

        // Log the constructed write command
        console.log('Constructed write command:', writeCommand);

        // Write the actual command
        await passthroughCharacteristic.writeValue(writeCommand);
        console.log('Write command sent successfully');
    } catch (error) {
        console.error('Error in setup and communicate with characteristics:', error);
    }
};



const readPassthroughValue = async (pin, nodeId, index, subindex, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    return new Promise(async (resolve, reject) => {
        let retries = 0;
        let timeoutId;

        const sendReadCommand = async () => {
            try {
                const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID
                const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
                if (!passthroughCharacteristic) {
                    console.error('Passthrough characteristic not found');
                    reject('Passthrough characteristic not found');
                    return;
                }

                // Construct the read command payload with exactly 17 bytes
                const readCommand = new Uint8Array(17);
                readCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
                readCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
                readCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
                readCommand[6] = (nodeId >> 8) & 0xFF;
                readCommand[7] = (nodeId >> 16) & 0xFF;
                readCommand[8] = (nodeId >> 24) & 0xFF;
                readCommand[9] = 0x40; // Payload Type: Command to read (1 byte)
                readCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
                readCommand[11] = (index >> 8) & 0xFF;
                readCommand[12] = subindex; // Sub Index (1 byte)
                // Remaining 4 bytes are zero-padded for the data payload

                // Log the constructed read command
                console.log('Constructed read command:', readCommand);

                // Write the command to the characteristic
                await passthroughCharacteristic.writeValue(readCommand);
                console.log('Write command sent successfully');
            } catch (error) {
                clearTimeout(timeoutId);
                stopNotificationListener(bleService);
                console.error('Error in sending read command:', error);
                reject(error);
            }
        };

        const notificationCallback = (event) => {
            const value = new Uint8Array(event.buffer);

            const canHeader = value.slice(1, 5);
            const canDataBytes = value.slice(5, 9);

            const res_data = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);
            const res_index = (canHeader[2] << 8) | canHeader[1];
            const res_subIndex = canHeader[3];

            console.log('notification read passthrough', index, res_index, subindex, res_subIndex, res_data);
            
            if (index === res_index && subindex === res_subIndex) {
                clearTimeout(timeoutId);
                stopNotificationListener(bleService);
                resolve(res_data);
            }
        };

        await startNotificationListener(bleService, notificationCallback);

        const attemptReadCommand = async () => {
            if (retries >= 3) {
                console.error('Maximum retries reached, no response received');
                stopNotificationListener(bleService);
                resolve(false);
                return;
            }

            await sendReadCommand();
            retries += 1;

            timeoutId = setTimeout(attemptReadCommand, 200); // Retry after 200ms
        };

        attemptReadCommand();
    });
};


//methods for retrieving characteristics while connected
  const getDeviceInformation = async (device, service, setLoadingScreen, setBleConnectionError) => {
    if (debugBLE) {
      setLoadingScreen(false);
      return "TESTBIKE001";
    }

    if (!service || !device?.gatt.connected) {
      Debug.logger(2, 'Device not connected or service unavailable');
      setBleConnectionError(true);
      setLoadingScreen(false);
      return;
    }

    try {
      const serialNumberCharacteristic = await service.getCharacteristic(Protocol.deviceInformation.characteristics.serialNumber);
      const serialNumber = await serialNumberCharacteristic.readValue();
      const serialNumberDec = dataViewToAsciiString(serialNumber);
      Debug.logger(2, "Serial Number of Controller", serialNumberDec);
      setBikeSerial(serialNumberDec);
      return serialNumberDec;
      // try {
      //   const firmwareCharacteristic = await service.getCharacteristic(Protocol.deviceInformation.characteristics.firmwareRevision);
      //   const firmwareNumber = await firmwareCharacteristic.readValue();
      //   Debug.logger(2, "Firmware Number of Controller", firmwareNumber);
      // } catch (error) {
      //   Debug.logger(1, 'failed to get firmware');
      // }

    } catch (error) {
      Debug.logger(2, 'Error fetching device information:', error);
      setBleConnectionError(true);
      setLoadingScreen(false);
    }
  };

  const getFirmwareVersion = async () => {
      if (!deviceInformationService) {
          Debug.logger(2, 'Device Information Service not available');
          return null;
      }

      try {
          const firmwareRevisionCharacteristicUuid = 0x2A26;
          const characteristic = await deviceInformationService.getCharacteristic(firmwareRevisionCharacteristicUuid);
          const firmwareData = await characteristic.readValue();
          return formatFirmwareVersion(firmwareData);
      } catch (error) {
          Debug.logger(2, 'Error fetching firmware version:', error);
          return null;
      }
  };

  const debugClearErrorFlag = () => {
    return new Promise((resolve) => {
      Debug.logger(3, 'debugClearError');
      if (debugBLE) {
        setDebugClearError(true);
        Debug.logger(3, 'debugBLE after true setDebugClearError', debugClearError);
        resolve(true);
      } else {
        Debug.logger(3, 'debugBLE after false setDebugClearError', debugClearError);
        resolve(false);
      }
    });
  };

  const getErrorCodeByBLE = async (setBikeError, returnEmpty) => {
    console.log('getErrorCodeByBLE')
    if (debugBLE) {
      console.log('getErrorCodeByBLE debugBLE')
      if (!returnEmpty) {
        setBikeCurrentError([{
          code: '4',
          description: 'Low Voltage',
          timestamp: new Date().toISOString(),
        }])
        return [{
          code: '4',
          description: 'Low Voltage',
          timestamp: new Date().toISOString(),
        }];        
      } else {
        setBikeCurrentError([])
        return [];
      }
    }

    if (!primaryService) {
      console.log('getErrorCodeByBLE !primaryService')
      Debug.logger(2, 'Primary service not available');
      return null;
    }
    Debug.logger(1, 'primary service found');
    try {
      const errorCharacteristic = await primaryService.getCharacteristic(Protocol.primaryService.characteristics.error);
      const errorValue = await errorCharacteristic.readValue();
      console.log('getErrorCodeByBLE TRY', errorValue)
      const errors = readErrorCodeCharacteristic(errorValue);
      console.log('BLE ERROR REAR', errorValue, errors);
      Debug.logger(2, 'Error code fetched dataview:', errorValue, errors);
      setBikeCurrentError(errors)

      return errors;
    } catch (error) {
      console.log('getErrorCodeByBLE CATCH', error)
      Debug.logger(2, 'Error fetching error code:', error);
      return null; // Handle error appropriately
    }
  };


//methods for updating current state
  const preFlightComplete = () => {
    setIsPreflightChecked(true);
  };

  const resetPreFlightComplete = () => {
    setIsPreflightChecked(false);
  };

  const checkBluetoothConnection = () => {
    return isConnected;
  };

//PLACEHOLDERS for future functionality
  const checkLTEConnection = async () => {
    // Implement actual LTE connectivity check
    return true; // Placeholder
  };

  const checkGPSFunction = async () => {
    // Implement actual GPS functionality check
    return true; // Placeholder
  };

  const checkBatteryVoltage = async () => {
    // Implement actual battery voltage check
    return 40; // Placeholder
  };

const updateControllerSettings = async (pin, updates, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        // Enter Configuration Mode with 2-byte command
        await sendPassthroughWrite(pin, 0x00000601, 0x2014, 0x00, new Uint8Array([0xA3, 0xD5]), bleService, 2);
        console.log('Sent [0xA3, 0xD5] to enter configuration mode');
        console.log('Controller entered configuration mode');
        await new Promise(resolve => setTimeout(resolve, 500));

        const convertToUint8Array = (value, size) => {
            const dataArray = new Uint8Array(size);
            for (let i = 0; i < size; i++) {
                dataArray[i] = (value >> (8 * i)) & 0xFF;
            }
            return dataArray;
        };

        // Apply updates
        for (const update of updates) {
            const { index, sub_index, newValue, size } = update;

            console.log(`Updated index ${index}, sub-index ${sub_index}`, convertToUint8Array(newValue, size));
            await sendPassthroughWrite(pin, 0x00000601, index, sub_index, convertToUint8Array(newValue, size), bleService, size);
            await new Promise(resolve => setTimeout(resolve, 250));
        }

        // Exit Configuration Mode with 2-byte command
        await sendPassthroughWrite(pin, 0x00000601, 0x2014, 0x00, new Uint8Array([0xE5, 0xC2]), bleService, 2);
        console.log('Sent [0xE5, 0xC2] to exit configuration mode');
        console.log('Controller exited configuration mode');
    } catch (error) {
        console.error('Error updating controller settings:', error);
    }
};


    // FIRMWARE UPDATE
    const performFirmwareUpdate = async (primaryService, pin, updateFile, callbackFunction) => {
      if (!primaryService || !pin || !updateFile) {
        console.error('Missing required parameters for firmware update.');
        return;
      }

      try {
        console.log('Connecting to device...');
        await device.gatt.connect(); // Ensure device is connected
        console.log('Device connected:', device);

        let firmwareService = await server.getPrimaryService(Protocol.otaService.service); // Trigger the pairing request
        console.log('Firmware service retrieved:', firmwareService);

        // Get the required characteristics using full UUIDs
        console.log('Retrieving OTA characteristics...');
        const otaCommandCharacteristic = await firmwareService.getCharacteristic(Protocol.otaService.characteristics.otaCommand);
        console.log('OTA Command Characteristic:', otaCommandCharacteristic);
        const otaStatusCharacteristic = await firmwareService.getCharacteristic(Protocol.otaService.characteristics.otaStatus);
        console.log('OTA Status Characteristic:', otaStatusCharacteristic);
        const dataValidationCharacteristic = await firmwareService.getCharacteristic(Protocol.otaService.characteristics.dataValidation);
        console.log('Data Validation Characteristic:', dataValidationCharacteristic);
        const dataTransferCharacteristic = await firmwareService.getCharacteristic(Protocol.otaService.characteristics.dataTransfer);
        console.log('Data Transfer Characteristic:', dataTransferCharacteristic);


        let iotReadyForTransfer = false;
        let readyForNextFrame = true;

        const handleDataValidationNotification = async (event) => {
            const value = event.target.value;
            const statusByte = value.getUint8(0);
            console.log('handleDataValidationNotification: Received data notification:', statusByte, iotReadyForTransfer);

            if (iotReadyForTransfer) {
                await trySendingNextFrame();
            }
        };

        const trySendingNextFrame = async () => {
            const maxRetries = 10; // 10 retries with 200ms intervals (2 seconds total)
            let retryCount = 0;

            while (!readyForNextFrame && retryCount < maxRetries) {
                await new Promise(resolve => setTimeout(resolve, 200)); // wait 200ms
                retryCount++;
            }

            if (readyForNextFrame) {
                readyForNextFrame = false;
                console.log('sending frame from notification');
                await sendNextFrame(); // make sure this is an async function if not already
                readyForNextFrame = true;
            } else {
                console.error('Firmware update failed: Unable to send next frame');
                callbackFunction(0, `Firmware update failed`);
                transferCompleted = true;
            }
        };

        // Start listening for notifications
        dataValidationCharacteristic.addEventListener('characteristicvaluechanged', handleDataValidationNotification);
        await dataValidationCharacteristic.startNotifications();

        const CHUNK_SIZE = 128;
        const totalFrames = Math.ceil(updateFile.length / CHUNK_SIZE);
        let frameCount = 0;
        let transferCompleted = false;
        const MAX_RETRIES = 3;
        let retryCount = 0;

        const handleNotification = async (event) => {
          const value = event.target.value;
          const statusByte = value.getUint8(0);
          console.log('Received notification:', statusByte);

          switch (statusByte) {
            case 0x00:
              console.log('Status normal, continuing...');
              break;
            case 0x01:
              console.log('Ready for firmware transfer.');
              if (!iotReadyForTransfer) {
                    console.log('sending first frame');
                  iotReadyForTransfer = true;
                  await sendNextFrame();
              }
              break;
            case 0x02:
              console.log('File transfer completed.');
              callbackFunction(100, 'Firmware update complete.');
              //transferCompleted = true;
              break;
            case 0x03:
              console.log('Forwarding file to other MCUs.');
              break;
            case 0x04:
              console.log('Forward Complete.');
              break;
            case 0x05:
              console.log('Applying DFU.');
              break;
            case 0x06:
              console.log('Applying Rollback.');
              break;
            case 0x07:
              console.log('Erasing File.');
              break;
            case 0x08:
              console.log('File Erased.');
              break;
            case 0x09:
              console.log('File transfer aborted.');
              break;
            case 0xF0:
              console.log('DFU Initialization Failed.');
              break;
            case 0xFA:
              console.log('DFU Error: image not valid.');
              break;
            case 0xFB:
              console.log('DFU Error: bad FW version.');
              break;
            case 0xFC:
              console.log('Forward Error.');
              break;
            case 0xFD:
              console.error('Critical DFU Error:', statusByte.toString(16).toUpperCase());
              if (iotReadyForTransfer) {
                  callbackFunction(0, `DFU Error: 0x${statusByte.toString(16).toUpperCase()}`);
                  transferCompleted = true;
              }
              break;
            case 0xFE:
              console.error('DFU Error: Corrupted Frame.', iotReadyForTransfer, frameCount);
              if (iotReadyForTransfer) {
                  if (retryCount < MAX_RETRIES) {
                    retryCount++;
                    await resendCurrentFrame();
                  } else {
                    console.error('Max retries reached for corrupted frame.');
                    transferCompleted = true;
                    callbackFunction(0, 'DFU Error: Max retries reached for corrupted frame.');
                  }
              }
              break;
            case 0xFF:
                console.error('DFU Error: Missing Frame.');
                if (iotReadyForTransfer) {
                    await resendPreviousFrame();
                }
                break;
            default:
              console.error('Unexpected status:', statusByte);
              callbackFunction(0, `Firmware update failed due to unexpected status code 0x${statusByte.toString(16).toUpperCase()}.`);
              if (iotReadyForTransfer) {
                transferCompleted = true;
              }
              break;
          }
        };

        // Start listening for notifications
        otaStatusCharacteristic.addEventListener('characteristicvaluechanged', handleNotification);
        await otaStatusCharacteristic.startNotifications();

        //write pin just in case
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID

        // Get passthrough characteristic
        const passthroughCharacteristic = await primaryService.getCharacteristic(passthroughCharacteristicUuid);
        if (!passthroughCharacteristic) {
            console.error('Passthrough characteristic not found');
            return;
        }

        const pinArray = new Uint8Array([...pin.split('').map(char => char.charCodeAt(0)), 48]);
        console.log('pinArray', pinArray)

        // Write the PIN to the characteristic
        console.log('sending pin')
        await passthroughCharacteristic.writeValue(pinArray);
        console.log('pin sent')

        // Prepare for file transfer
        console.log('Preparing for file transfer...');
        await otaCommandCharacteristic.writeValue(new Uint8Array([0x01]));
        console.log('Prepared for file transfer...');

        const sendNextFrame = async () => {
          if (frameCount >= totalFrames || transferCompleted) return;

          const chunk = updateFile.slice(frameCount * CHUNK_SIZE, (frameCount + 1) * CHUNK_SIZE);
          const frame = buildFrame(chunk, frameCount, totalFrames);
          console.log(`Sending frame ${frameCount + 1}/${totalFrames}`);

          try {
            await dataTransferCharacteristic.writeValue(frame);

            const progress = Math.round(((frameCount + 1) / totalFrames) * 100);
            callbackFunction(progress, `Frame ${frameCount + 1}/${totalFrames} sent successfully.`);
            
            frameCount++;
            retryCount = 0; // Reset retry count after successful send

            // Call sendNextFrame to continue with the next frame
            // sendNextFrame();
          } catch (error) {
            console.error(`Error sending frame ${frameCount + 1}/${totalFrames}:`, error);
            // Handle error (e.g., retry logic or error handling)
          }
        };


        const resendCurrentFrame = async () => {
          console.log(`Resending frame ${frameCount}/${totalFrames}`);
          await sendNextFrame();
        };

        const resendPreviousFrame = async () => {
          console.log(`Resending previous frame ${frameCount}/${totalFrames}`);
          frameCount -= 1; // Step back to resend the previous frame
          await sendNextFrame();
        };

        const buildFrame = (chunk, frameCount, totalFrames) => {
          const frameSize = 5 + chunk.length + 1; // 5 header bytes, chunk size, and 1 CRC byte
          const frame = new Uint8Array(frameSize);
          
          frame[0] = 0xAA; // Preamble
          frame[1] = frameCount & 0xFF; // Frame count low byte
          frame[2] = (frameCount >> 8) & 0xFF; // Frame count high byte
          frame[3] = frameCount === 0 ? 0x00 : (frameCount + 1 >= totalFrames ? 0x02 : 0x01); // Frame Ctrl
          frame[4] = chunk.length; // Length of the chunk
          
          frame.set(chunk, 5); // Set the chunk starting at index 5
          
          const crcIndex = 5 + chunk.length; // Calculate the index for CRC
          frame[crcIndex] = calculateCRC8(frame.slice(0, crcIndex)); // Calculate and set CRC
          
          console.log('frame', frame, frame.length, totalFrames);
          return frame;
        };

        // Start the process by sending the first frame
        //await sendNextFrame();

        // Wait until the transfer is completed
        await new Promise((resolve) => {
          const checkCompletion = () => {
            if (transferCompleted) resolve();
            else setTimeout(checkCompletion, 100);
          };
          checkCompletion();
        });

        // Clean up notification listeners
        // await otaStatusCharacteristic.stopNotifications();
        // otaStatusCharacteristic.removeEventListener('characteristicvaluechanged', handleNotification);

      } catch (error) {
        console.error('Firmware update failed:', error);
        callbackFunction(0, 'Firmware update failed.');
      }
    };


    function calculateCRC8(buf) {
        const tableCRC8 = [
            0x00, 0x07, 0x0E, 0x09, 0x1C, 0x1B, 0x12, 0x15, 0x38, 0x3F, 0x36, 0x31, 0x24, 0x23, 0x2A, 0x2D,
            0x70, 0x77, 0x7E, 0x79, 0x6C, 0x6B, 0x62, 0x65, 0x48, 0x4F, 0x46, 0x41, 0x54, 0x53, 0x5A, 0x5D,
            0xE0, 0xE7, 0xEE, 0xE9, 0xFC, 0xFB, 0xF2, 0xF5, 0xD8, 0xDF, 0xD6, 0xD1, 0xC4, 0xC3, 0xCA, 0xCD,
            0x90, 0x97, 0x9E, 0x99, 0x8C, 0x8B, 0x82, 0x85, 0xA8, 0xAF, 0xA6, 0xA1, 0xB4, 0xB3, 0xBA, 0xBD,
            0xC7, 0xC0, 0xC9, 0xCE, 0xDB, 0xDC, 0xD5, 0xD2, 0xFF, 0xF8, 0xF1, 0xF6, 0xE3, 0xE4, 0xED, 0xEA,
            0xB7, 0xB0, 0xB9, 0xBE, 0xAB, 0xAC, 0xA5, 0xA2, 0x8F, 0x88, 0x81, 0x86, 0x93, 0x94, 0x9D, 0x9A,
            0x27, 0x20, 0x29, 0x2E, 0x3B, 0x3C, 0x35, 0x32, 0x1F, 0x18, 0x11, 0x16, 0x03, 0x04, 0x0D, 0x0A,
            0x57, 0x50, 0x59, 0x5E, 0x4B, 0x4C, 0x45, 0x42, 0x6F, 0x68, 0x61, 0x66, 0x73, 0x74, 0x7D, 0x7A,
            0x89, 0x8E, 0x87, 0x80, 0x95, 0x92, 0x9B, 0x9C, 0xB1, 0xB6, 0xBF, 0xB8, 0xAD, 0xAA, 0xA3, 0xA4,
            0xF9, 0xFE, 0xF7, 0xF0, 0xE5, 0xE2, 0xEB, 0xEC, 0xC1, 0xC6, 0xCF, 0xC8, 0xDD, 0xDA, 0xD3, 0xD4,
            0x69, 0x6E, 0x67, 0x60, 0x75, 0x72, 0x7B, 0x7C, 0x51, 0x56, 0x5F, 0x58, 0x4D, 0x4A, 0x43, 0x44,
            0x19, 0x1E, 0x17, 0x10, 0x05, 0x02, 0x0B, 0x0C, 0x21, 0x26, 0x2F, 0x28, 0x3D, 0x3A, 0x33, 0x34,
            0x4E, 0x49, 0x40, 0x47, 0x52, 0x55, 0x5C, 0x5B, 0x76, 0x71, 0x78, 0x7F, 0x6A, 0x6D, 0x64, 0x63,
            0x3E, 0x39, 0x30, 0x37, 0x22, 0x25, 0x2C, 0x2B, 0x06, 0x01, 0x08, 0x0F, 0x1A, 0x1D, 0x14, 0x13,
            0xAE, 0xA9, 0xA0, 0xA7, 0xB2, 0xB5, 0xBC, 0xBB, 0x96, 0x91, 0x98, 0x9F, 0x8A, 0x8D, 0x84, 0x83,
            0xDE, 0xD9, 0xD0, 0xD7, 0xC2, 0xC5, 0xCC, 0xCB, 0xE6, 0xE1, 0xE8, 0xEF, 0xFA, 0xFD, 0xF4, 0xF3 
        ];
        let crc = 0; // UInt8.min in JavaScript
        for (let i = 0; i < buf.length; i++) {
            const byte = buf[i];
            const x = crc ^ byte;
            crc = tableCRC8[x];
        }
        return crc;
    }

  return {
    isConnected,
    isPreflightChecked,
    preFlightComplete,
    resetPreFlightComplete,
    debugClearError,
    connectToDevice,
    disconnectFromDevice,
    debugClearErrorFlag,
    checkBluetoothConnection,
    getDebugState,
    bikeSerial,
    getErrorCodeByBLE,
    getFirmwareVersion,
    verifyPin,
    primaryService,
    startNotificationListener,
    stopNotificationListener,
    writePassthroughCharacteristic,
    readPassthroughValue,
    sendPassthroughRead,
    sendPassthroughWrite,
    getSendPassthroughRW,
    performFirmwareUpdate,
    updateControllerSettings,
  };
};

export default useBluetooth;
