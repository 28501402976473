import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar, Table, Card, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useSerialNumber from "../../hooks/useSerialNumber.hook";

const AppTesterComponent = ({
    pin,
    primaryService,
    startNotificationListener,
    stopNotificationListener,
    getSendPassthroughRW,
    getDeviceVersionInfo
}) => {
    const { t } = useTranslation();
    const { serialNumber, updateMsb, updateLsb, reset } = useSerialNumber();

    // State management
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [error, setError] = useState(null);
    const [readWritePassthrough, setReadWritePassthrough] = useState(null);
    const [maxPasLevel, setMaxPasLevel] = useState(null);
    const [isWriting, setIsWriting] = useState(false);
    const [values, setValues] = useState({
        maxPower: { value: null, lastUpdate: null },
        power: { value: null, lastUpdate: null },
        pasLevel: { value: null, lastUpdate: null },
        batterySOC: { value: null, lastUpdate: null },
        speed: { value: null, lastUpdate: null },
        errors: { value: null, lastUpdate: null },
        packVersion: { value: null, lastUpdate: null },
        max_level: { value: null, lastUpdate: null },
        serial_msb: { value: null, lastUpdate: null },
        serial_lsb: { value: null, lastUpdate: null }
    });

    // Refs for managing operations
    const gattQueueRef = useRef([]);
    const gattOperationInProgressRef = useRef(false);
    const intervalsRef = useRef([]);
    const loopCounterRef = useRef(0);

    // Protocol definitions separated by update frequency
    const protocols = {
        realtime: [
            { key: 'maxPower', index: '0x2005', subIndex: '0x00', name: 'Max Power', unit: 'W' },
            { key: 'power', index: '0x2001', subIndex: '0x00', name: 'Total Power', unit: 'W' },
            { key: 'pasLevel', index: '0x2003', subIndex: '0x00', name: 'PAS Level', unit: '' },
            { key: 'batterySOC', index: '0x2002', subIndex: '0x00', name: 'Battery SOC', unit: '%' },
            { key: 'speed', index: '0x2000', subIndex: '0x01', name: 'Speed', unit: 'km/h x 10' },
            { key: 'errors', index: '0x2006', subIndex: '0x00', name: 'Errors', unit: '' }
        ],
        static: [
            { key: 'packVersion', index: '0x2008', subIndex: '0x00', name: 'Pack Version', unit: '' },
            { key: 'max_level', index: '0x2004', subIndex: '0x00', name: 'Maximum PAS', unit: '' },
            { key: 'serial_msb', index: '0x2007', subIndex: '0x00', name: 'Serial MSB', unit: '' },
            { key: 'serial_lsb', index: '0x2007', subIndex: '0x01', name: 'Serial LSB', unit: '' }
        ]
    };

    // Helper functions
    const parseIndex = (indexStr) => {
        if (!indexStr) return null;
        if (typeof indexStr === 'number') return indexStr;
        return parseInt(indexStr.replace('0x', ''), 16);
    };

    const decodePassthroughResponse = (response) => {
        if (response.length !== 9) {
            console.error('Invalid response length:', response.length);
            return null;
        }

        const canHeader = response.slice(1, 5);
        const canDataBytes = response.slice(5, 9);
        const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

        const msgIndex = (canHeader[2] << 8) | canHeader[1];

        const msgSubIndex = canHeader[3];

        return { msgIndex, msgSubIndex, canData };
    };

    const formatLastUpdate = (timestamp) => {
        return timestamp ? new Date(timestamp).toLocaleTimeString() : 'N/A';
    };

    // Queue management
    const enqueueGattOperation = (args = []) => {
        const operationKey = `function-${JSON.stringify(args)}`;
        const isDuplicate = gattQueueRef.current.some(op => op.key === operationKey);

        if (args[0] === 'write') {
            gattQueueRef.current.push({ args, key: operationKey });
        } else if (!isDuplicate) {
            gattQueueRef.current.push({ args, key: operationKey });
        }
    };

    const runNextGattOperation = async () => {
        if (!readWritePassthrough || !pin || gattOperationInProgressRef.current) return;

        if (gattQueueRef.current.length > 0) {
            const nextOperationIndex = gattQueueRef.current.findIndex(op => op.args[0] === 'write');
            const operation = nextOperationIndex !== -1 
                ? gattQueueRef.current.splice(nextOperationIndex, 1)[0] 
                : gattQueueRef.current.shift();

            try {
                gattOperationInProgressRef.current = true;
                await readWritePassthrough(...operation.args);
            } catch (error) {
                console.error('Error running GATT operation:', error);
            } finally {
                gattOperationInProgressRef.current = false;
                setTimeout(runNextGattOperation, 50);
            }
        }
    };

    // Notification handling
    const notificationCallback = (event) => {
        if (!event) return;

        const value = new Uint8Array(event.buffer);
        if (value[0] !== 'P'.charCodeAt(0)) return;

        const result = decodePassthroughResponse(value);
        if (!result) return;

        const { msgIndex, msgSubIndex, canData } = result;
        
        if (msgIndex === parseIndex('0x2007')) {
            if (msgSubIndex === parseIndex('0x00')) {
                updateMsb(value);
            } else if (msgSubIndex === parseIndex('0x01')) {
                updateLsb(value);
            }
        }

        // Check both realtime and static protocols
        [...protocols.realtime, ...protocols.static].forEach(({ key, index, subIndex }) => {
            const protocolIndex = parseIndex(index);
            const protocolSubindex = parseIndex(subIndex);
            
            if (protocolIndex === msgIndex && protocolSubindex === msgSubIndex) {
                setValues(prev => ({
                    ...prev,
                    [key]: { value: canData, lastUpdate: Date.now() }
                }));
            }
        });
    };

    // Effects
    useEffect(() => {
        if (primaryService) {
            getSendPassthroughRW(primaryService).then((sendFunction) => {
                if (sendFunction) {
                    setReadWritePassthrough(() => sendFunction);
                } else {
                    console.error('Failed to initialize passthrough function');
                }
            });
        }
    }, [primaryService, getSendPassthroughRW]);

    useEffect(() => {
        const fetchDeviceInfo = async () => {
            try {
                const info = await getDeviceVersionInfo();
                setDeviceInfo(info);
            } catch (err) {
                setError('Failed to fetch device info');
                console.error('Error fetching device info:', err);
            }
        };

        fetchDeviceInfo();
    }, [getDeviceVersionInfo]);

    useEffect(() => {
        const startMonitoring = async () => {
            if (isMonitoring && readWritePassthrough && pin && !isWriting) {
                try {
                    // Stop any existing notifications first
                    await stopNotificationListener(primaryService);
                    await new Promise(resolve => setTimeout(resolve, 100));
                    
                    // Start new notification listener
                    await startNotificationListener(primaryService, notificationCallback);
                    
                    // Initial read of static parameters
                    protocols.static.forEach(protocol => {
                        const protocolIndex = parseIndex(protocol.index);
                        const protocolSubindex = parseIndex(protocol.subIndex);
                        
                        if (protocolIndex !== null && protocolSubindex !== null) {
                            enqueueGattOperation([
                                'read',
                                pin,
                                '0x00000601',
                                protocolIndex,
                                protocolSubindex
                            ]);
                        }
                    });

                    // Set up monitoring interval
                    const interval = setInterval(() => {
                        if (!gattOperationInProgressRef.current) {
                            // Increment loop counter
                            loopCounterRef.current = (loopCounterRef.current + 1) % 10;

                            // Always fetch realtime parameters
                            protocols.realtime.forEach(protocol => {
                                const protocolIndex = parseIndex(protocol.index);
                                const protocolSubindex = parseIndex(protocol.subIndex);
                                
                                if (protocolIndex !== null && protocolSubindex !== null) {
                                    enqueueGattOperation([
                                        'read',
                                        pin,
                                        '0x00000601',
                                        protocolIndex,
                                        protocolSubindex
                                    ]);
                                }
                            });

                            // Fetch static parameters every 10th loop
                            if (loopCounterRef.current === 0) {
                                protocols.static.forEach(protocol => {
                                    const protocolIndex = parseIndex(protocol.index);
                                    const protocolSubindex = parseIndex(protocol.subIndex);
                                    
                                    if (protocolIndex !== null && protocolSubindex !== null) {
                                        enqueueGattOperation([
                                            'read',
                                            pin,
                                            '0x00000601',
                                            protocolIndex,
                                            protocolSubindex
                                        ]);
                                    }
                                });
                            }
                        }
                        runNextGattOperation();
                    }, 100);

                    intervalsRef.current.push(interval);

                } catch (error) {
                    console.error('Error starting monitoring:', error);
                    setError('Failed to start monitoring');
                    setIsMonitoring(false);
                }
            }
        };

        startMonitoring();

        return () => {
            // Clear all intervals
            if (intervalsRef.current) {
                intervalsRef.current.forEach(clearInterval);
                intervalsRef.current = [];
            }
            
            // Stop notifications
            if (primaryService) {
                stopNotificationListener(primaryService);
            }
            
            // Reset refs
            loopCounterRef.current = 0;
            gattQueueRef.current = [];
            gattOperationInProgressRef.current = false;
        };
    }, [isMonitoring, readWritePassthrough, pin, primaryService, isWriting]);

    // Event handlers
    const toggleMonitoring = async () => {
        try {
            if (!isMonitoring) {
                // Starting monitoring
                if (!readWritePassthrough || !pin) {
                    setError('Cannot start monitoring - missing required connection');
                    return;
                }
                setIsMonitoring(true);
            } else {
                // Stopping monitoring
                if (primaryService) {
                    await stopNotificationListener(primaryService);
                }
                
                // Clear intervals
                if (intervalsRef.current) {
                    intervalsRef.current.forEach(clearInterval);
                    intervalsRef.current = [];
                }
                
                // Reset values except static ones
                setValues(prev => {
                    const resetValues = { ...prev };
                    Object.keys(resetValues).forEach(key => {
                        if (protocols.realtime.some(p => p.key === key)) {
                            resetValues[key] = { value: null, lastUpdate: null };
                        }
                    });
                    return resetValues;
                });
                
                // Reset monitoring state
                setIsMonitoring(false);
                loopCounterRef.current = 0;
                gattQueueRef.current = [];
                gattOperationInProgressRef.current = false;
            }
        } catch (error) {
            console.error('Error toggling monitoring:', error);
            setError('Failed to toggle monitoring');
            setIsMonitoring(false);
        }
    };

    const handlePasLevelChange = async (level) => {
        if (!readWritePassthrough || !pin || isWriting) return;

        try {
            setIsWriting(true);
            const wasMonitoring = isMonitoring;
            
            if (wasMonitoring) {
                setIsMonitoring(false);
                // Stop notifications and intervals
                if (primaryService) {
                    await stopNotificationListener(primaryService);
                }
                intervalsRef.current.forEach(clearInterval);
                intervalsRef.current = [];
                await new Promise(resolve => setTimeout(resolve, 200));
            }

            // Clear queue and write new PAS level
            gattQueueRef.current = [];
            await readWritePassthrough(
                'write',
                pin,
                '0x00000601',
                parseIndex('0x2003'),
                parseIndex('0x00'),
                level
            );

            await new Promise(resolve => setTimeout(resolve, 200));

            // Restart monitoring if it was active
            if (wasMonitoring) {
                setIsMonitoring(true);
            }
        } catch (error) {
            console.error('Error changing PAS level:', error);
            setError('Failed to change PAS level');
        } finally {
            setTimeout(() => setIsWriting(false), 200);
        }
    };

    // Computed values
    const currentPasLevel = values.pasLevel?.value ?? 0;
    const effectiveMaxPasLevel = maxPasLevel ?? 9;

    const hasNAValue = (value) => {
        return value === null || value === 'N/A';
    };

    return (
        <div className="container-fluid">
            {/* Static Parameters Card */}
            <Card className="mb-4">
                <Card.Header>
                    <h3>{t("Static Parameters")}</h3>
                </Card.Header>
                <Card.Body>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>{t("Parameter")}</th>
                                <th>{t("Value")}</th>
                                <th>{t("Last Update")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={hasNAValue(deviceInfo?.serialNumber) ? 'bg-danger bg-opacity-25' : ''}>
                                <td>{t("Serial Number(BLE)")}</td>
                                <td>{deviceInfo?.serialNumber ?? 'N/A'}</td>
                                <td></td>
                            </tr>
                            <tr className={hasNAValue(serialNumber) ? 'bg-danger bg-opacity-25' : ''}>
                                <td>{t("Serial Number(CAN)")}</td>
                                <td>{serialNumber ?? 'N/A'}</td>
                                <td></td>
                            </tr>
                            {protocols.static.map(({ key, name, unit }) => (
                                <tr 
                                    key={key}
                                    className={hasNAValue(values[key].value) ? 'bg-danger bg-opacity-25' : ''}
                                >
                                    <td>{name}</td>
                                    <td>{values[key].value !== null ? `${values[key].value}${unit}` : 'N/A'}</td>
                                    <td>{formatLastUpdate(values[key].lastUpdate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* Realtime Parameters Card */}
            <Card className="mb-4">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h3>{t("Real-time Parameters")}</h3>
                    <Button 
                        onClick={toggleMonitoring}
                        variant={isMonitoring ? "danger" : "success"}
                        disabled={isWriting}
                    >
                        {isMonitoring ? t("Stop Monitoring") : t("Start Monitoring")}
                    </Button>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>{t("Parameter")}</th>
                                <th>{t("Value")}</th>
                                <th>{t("Index")}</th>
                                <th>{t("Subindex")}</th>
                                <th>{t("Last Update")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {protocols.realtime.map(({ key, name, index, subIndex, unit }) => (
                                <tr 
                                    key={key}
                                    className={hasNAValue(values[key].value) ? 'bg-danger bg-opacity-25' : ''}
                                >
                                    <td>{name}</td>
                                    <td>
                                        {key === 'batterySOC' && values[key].value !== null ? (
                                            <>
                                                {values[key].value}{unit}
                                                <ProgressBar 
                                                    now={values[key].value} 
                                                    variant={values[key].value > 20 ? "success" : "danger"}
                                                    className="mt-2"
                                                />
                                            </>
                                        ) : (
                                            values[key].value !== null ? `${values[key].value}${unit}` : 'N/A'
                                        )}
                                    </td>
                                    <td>{index}</td>
                                    <td>{subIndex}</td>
                                    <td>{formatLastUpdate(values[key].lastUpdate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* Vehicle Control Card remains the same */}
            <Card>
                <Card.Header>
                    <h3>{t("Vehicle Control")}</h3>
                </Card.Header>
                <Card.Body>
                    {!maxPasLevel && (
                        <div className="alert alert-warning">
                            {t("Warning: Maximum PAS level configuration is not available. Using default maximum of 9.")}
                        </div>
                    )}
                    <div>
                        <h4>{t("PAS Level Control")}</h4>
                        <ButtonGroup>
                            {[...Array(effectiveMaxPasLevel + 1)].map((_, level) => (
                                <Button
                                    key={level}
                                    variant={level === currentPasLevel ? "primary" : "outline-primary"}
                                    onClick={() => handlePasLevelChange(level)}
                                    disabled={isWriting}
                                >
                                    {isWriting && level === currentPasLevel ? '...' : level}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default AppTesterComponent;