import { useState, useEffect } from 'react';
import Debug from "../services/debug.js";
import { useDebug } from '../hooks/useDebugContext';
import { useTranslation } from 'react-i18next';
import { DFUManager } from './DFU';

// Set up console logging controls
window.canDebug = {
  enableLogs: false,
  
  // Toggle logging on/off
  toggle: function() {
    this.enableLogs = !this.enableLogs;
    console.log(`CAN message logging: ${this.enableLogs ? 'ENABLED' : 'DISABLED'}`);
    return this.enableLogs;
  },
  
  // Enable logging
  enable: function() {
    this.enableLogs = true;
    console.log('CAN message logging: ENABLED');
    return true;
  },
  
  // Disable logging
  disable: function() {
    this.enableLogs = false;
    console.log('CAN message logging: DISABLED');
    return false;
  }
};

// Helper function to log CAN messages
const logRawCANMessage = (label, data) => {
  if (!window.canDebug.enableLogs) return;
  
  const hexData = Array.from(data)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join(' ');
  
  console.log(`${label}: [${hexData}]`);
};

const useBluetooth = (debugFlag) => {
  const { t, i18n } = useTranslation();

  const [isConnected, setIsConnected] = useState(false);
  const [isPreflightChecked, setIsPreflightChecked] = useState(false);
  const [bikeSerial, setBikeSerial] = useState(null);
  const [bikeCurrentError, setBikeCurrentError] = useState(null);
  const [device, setDevice] = useState(null);
  const [server, setServer] = useState(null);
  const [primaryService, setPrimaryService] = useState(null);
  const [OTAService, setOTAService] = useState(null);
  const [debugClearError, setDebugClearError] = useState(false);
  const [deviceInformationService, setDeviceInformationService] = useState(null);
  const [bleConnectionError, setBleConnectionError] = useState(false);

  const { debugBLE } = useDebug(); // Use debugBLE from DebugContext

  Debug.logger(3, 'debugBLE hook', debugBLE)

//variables
  const errorDescriptions = {
    0x00000000: t("No error"),
    0x00000001: t("Throttle stuck"),
    0x00000002: t("Under voltage"),
    0x00000004: t("Over voltage"),
    0x00000008: t("Under temperature"),
    0x00000010: t("Motor Over temperature"),
    0x00000020: t("Motor foldback temperature"),
    0x00000040: t("Motor NTC Disconnected/ low temperature"),
    0x00000080: t("Controller over temperature"),
    0x00000100: t("Controller foldback temperature"),
    0x00000200: t("Motor Hall error"),
    0x00000400: t("Motor Phase Error"),
    0x00000800: t("IoT Comm Error"),
    0x00001000: t("Dual Comm Error"),
    0x00002000: t("Over Current"),
    0x00004000: t("Battery Low"),
    0x00008000: t("PAS Sensor Error"),
    0x00010000: t("Controller Error"),
    0x00020000: t("Brake Error"),
    0x00040000: t("Screen Communication Error"),
    0x00080000: t("Torque Sensor Stuck Error"),
    0x00200000: t("Battery Error"),
    0x00400000: t("Powertrain Locked")
  };

  const cloudDriveErrorMapping = {
    1: '7', // Throttle stuck
    2: '4', // Under voltage
    4: '12', // Over voltage
    8: '8', // Under temperature
    16: '11', // Motor Over temperature
    32: '11', // Motor foldback temperature
    64: '14', // Motor NTC Disconnected/ low temperature
    128: '9', // Controller over temperature
    256: '9', // Controller foldback temperature
    512: '6', // Motor Hall error
    1024: '3', // Motor Phase Error
    2048: '10', // IoT Comm Error
    // 4096: '19', // Dual comm error. N/A on Clouddrive for now
    // 8192: '14', // Over Current. N/A on Clouddrive for now
    16384: '13', // Battery Low
    32768: '1', // PAS Sensor Error
    65536: '2', // Controller Anomaly
    131072: '5'  // Brake Error
  };

  const Protocol = {
    prefixEvionics: "EvionicsO",// "EvionicsO",
    prefixFTEX: "FTEXO",// "EvionicsO",
    genericAccess: {
      service: 0x1800,
      characteristics: {
        deviceName: 0x2A00,
        appearance: 0x2A01,
      }
    },
    deviceInformation: {
      service: 0x180A,
      characteristics: {
        manufacturer: 0x2A29,
        serialNumber: 0x1111,
        firmwareRevision: 0x2A26,
        hardwareRevision: 0x2A27,
      }
    },
    primaryService: {
      service: "e8012f0f-6630-4a43-80e6-5b3986e2f2d7",
      characteristics: {
        pas: 0x2F11,
        status: 0x2F12,
        error: "e8012f13-6630-4a43-80e6-5b3986e2f2d7",
        configPayload: 0x2F14,
      }
    },
    otaService: {
      service: "e8012ff0-6630-4a43-80e6-5b3986e2f2d7",
      characteristics: {
        otaCommand: "e8012ff1-6630-4a43-80e6-5b3986e2f2d7",
        otaStatus: "e8012ff2-6630-4a43-80e6-5b3986e2f2d7",
        dataValidation: "e8012ff3-6630-4a43-80e6-5b3986e2f2d7",
        dataTransfer: "e8012ff4-6630-4a43-80e6-5b3986e2f2d7",
      }
    }
  }

//helper functions for formating
const dataViewToAsciiString = (dataView) => {
    let asciiString = '';
    for (let i = 0; i < dataView.byteLength; i++) {
        // Convert each byte to hexadecimal
        Debug.logger(1, 'dataView.getUint8(i).toString(16)', dataView.getUint8(i).toString(16));
        const hexValue = dataView.getUint8(i).toString(16);
        // Convert the hexadecimal value to ASCII character
        Debug.logger(1, 'String.fromCharCode(parseInt(hexValue, 16))', String.fromCharCode(parseInt(hexValue, 16)))
        const asciiChar = String.fromCharCode(parseInt(hexValue, 16));
        asciiString += asciiChar;
    }
    return asciiString;
};

const readErrorCodeCharacteristic = (dataView) => {
    const byteLength = dataView.byteLength;
    if (byteLength !== 8 && byteLength !== 9) {
        throw new Error("Invalid data length for error code characteristic");
    }

    let errorCode, timestamp, dataType;
    
    if (byteLength === 8) {
        // Old protocol
        errorCode = dataView.getUint32(0, true); // true for little-endian
        timestamp = dataView.getUint32(4, true); // true for little-endian
    } else if (byteLength === 9) {
        // New protocol
        dataType = dataView.getUint8(0); // 1 byte for data type
        errorCode = dataView.getUint32(1, true); // 4 bytes for error code
        timestamp = dataView.getUint32(5, true); // 4 bytes for timestamp
    }

    const binaryString = errorCode.toString(2).padStart(32, '0');

    const currentErrors = [];
    Object.keys(errorDescriptions).forEach((key, index) => {
        if (binaryString[31 - index] === '1') {
            currentErrors.push({
                code: cloudDriveErrorMapping[key],
                description: errorDescriptions[key],
                timestamp: new Date(timestamp * 1000).toISOString(),
            });
        }
    });

    return currentErrors.length > 0 ? currentErrors : [{
        code: '0',
        description: 'No error',
        timestamp: new Date(timestamp * 1000).toISOString(),
    }];
};


const getDebugState = () => {
  return debugBLE;
}



  const formatFirmwareVersion = (dataView) => {
      // where MM is major, mm is minor, and pppp is patch part.
      const major = dataView.getUint8(0).toString().padStart(2, '0');
      const minor = dataView.getUint8(1).toString().padStart(2, '0');
      const patch = (dataView.getUint8(2) | (dataView.getUint8(3) << 8)).toString().padStart(4, '0');

      return `${major}.${minor}.${patch}`;
  };

//bluetooth connection methods
  const handleDisconnect = (setLoadingScreen) => {
    Debug.logger(1, 'Bluetooth connection has been terminated');
    setIsConnected(false);
    setIsPreflightChecked(false);
    setDevice(null);
    setServer(null);
    setBikeSerial(null);
    setPrimaryService(null);
    setDeviceInformationService(null);
    setDebugClearError(null);
    if (typeof setLoadingScreen === 'function') {
      setLoadingScreen(false);
    }
  };

  useEffect(() => {
    if (device) {
      device.addEventListener('gattserverdisconnected', handleDisconnect);
    }
    return () => {
      if (device) {
        device.removeEventListener('gattserverdisconnected', handleDisconnect);
      }
    };
  }, [device]);

const connectToDevice = async (setLoadingScreen, setBleConnectionError) => {
    await disconnectFromDevice();

    if (debugBLE) {
        setIsConnected(true);
        setBikeSerial("TESTBIKE001");
        setBikeCurrentError([{
            code: '4',
            description: 'Low Voltage',
            timestamp: new Date().toISOString(),
        }]);
        if (typeof setBleConnectionError === 'function') {
          setBleConnectionError(false);
        }
        if (typeof setLoadingScreen === 'function') {
          setLoadingScreen(false);
        }
        Debug.logger(3, 'In debug mode');
        return;
    }

    const bluetoothAvailability = await navigator.bluetooth.getAvailability();
    if (!bluetoothAvailability) {
        Debug.logger(3, 'Bluetooth is not available on this device or browser version');
        setBleConnectionError(true);
        if (typeof setLoadingScreen === 'function') {
          setLoadingScreen(false);
        }
        getDeviceInformation();
        return;
    }

    handleDisconnect();

    try {
        const options = {
            filters: [{ namePrefix: Protocol.prefixEvionics }, { namePrefix: Protocol.prefixFTEX }],
            optionalServices: [Protocol.deviceInformation.service, Protocol.primaryService.service, Protocol.otaService.service],
        };

        const bleDevice = await navigator.bluetooth.requestDevice(options);

        setDevice(bleDevice);

        Debug.logger(3, 'bleDevice', bleDevice);

        const bleServer = await bleDevice.gatt.connect();
        setServer(bleServer);
        Debug.logger(3, 'bleServer', bleServer);

        if (!bleDevice.gatt.connected) {
            throw new Error("Failed to connect to the GATT Server.");
        }

        // Trigger bonding (pairing) if necessary
        await bleDevice.gatt.connect(); // Ensure device is connected
        await bleServer.getPrimaryService(Protocol.deviceInformation.service); // Trigger the pairing request

        const primaryServiceforBLE = await bleServer.getPrimaryService(Protocol.primaryService.service);
        await setPrimaryService(primaryServiceforBLE);
        Debug.logger(3, 'Primary service set:', primaryServiceforBLE, primaryService);

        const otaServiceforBLE = await bleServer.getPrimaryService(Protocol.otaService.service);
        await setOTAService(otaServiceforBLE);
        Debug.logger(3, 'OTA service set:', otaServiceforBLE, OTAService);

        const deviceInfoService = await bleServer.getPrimaryService(Protocol.deviceInformation.service);
        setDeviceInformationService(deviceInfoService);

        const serial = await getDeviceInformation(bleDevice, deviceInfoService, setLoadingScreen, setBleConnectionError);
        setIsConnected(true);
        return serial;
    } catch (error) {
        Debug.logger(2, 'Error connecting to the Bluetooth device:', error);
        if (typeof setBleConnectionError === 'function') {
          setBleConnectionError(false);
        }
        if (typeof setLoadingScreen === 'function') {
          setLoadingScreen(false);
        }
        handleDisconnect(setLoadingScreen);
    }
};

  const disconnectFromDevice = () => {
    if (debugBLE) {
      handleDisconnect();
    }

    if (device?.gatt.connected) {
      device.gatt.disconnect();
      Debug.logger(2, 'Disconnected from Bike');
      handleDisconnect();
    }
  };

//enter pin
  const submitPin = async (pinNumber, primaryService) => {
    if (!pinNumber) {
        Debug.logger(1, 'Pin is Required');
        return;
    }
    if (!primaryService) {
        Debug.logger(1, 'Primary service not available');
        return;
    }

    try {
        const pinCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7';
        const pinCharacteristic = await primaryService.getCharacteristic(pinCharacteristicUuid);

        // Format the PIN number. PIN is 4 bytes, and one byte for Confirm/Set.
        // Assuming PIN is a string and needs to be converted into bytes.
        const pinData = new TextEncoder().encode(pinNumber);
        const confirmSetByte = new Uint8Array([0]); // Set to 0 for confirm, 1 for set new PIN.
        const pinPayload = new Uint8Array([...pinData, ...confirmSetByte]);
          Debug.logger(1, 'pinPayload', pinPayload, bikeSerial);
        // Write the PIN to the characteristic
        let res = await pinCharacteristic.writeValue(pinPayload);
        Debug.logger(1, 'PIN submitted successfully', res);
    } catch (error) {
        Debug.logger(1, 'Error submitting PIN:', error);
    }
  };

  const verifyPin = async (pin, bleService) => {
      if (!pin) {
          Debug.logger(1, 'Pin is Required');
          return;
      }
      if (!bleService) {
          Debug.logger(1, 'Primary service not available', primaryService, bleService);
          return false;
      }

      try {
          const pinCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7';
          const pinCharacteristic = await bleService.getCharacteristic(pinCharacteristicUuid);

          // Format the PIN number. PIN is 4 bytes, followed by the confirm byte (0).
          const pinArray = new Uint8Array([...pin.split('').map(char => char.charCodeAt(0)), 0]);
          
          await pinCharacteristic.writeValue(pinArray);
          Debug.logger(1, 'PIN submitted successfully', pinArray);

          // Assume if not disconnected, PIN is correct
          return true;
      } catch (error) {
          Debug.logger(1, 'Error verifying PIN:', error);
          return false;
      }
  };

  const parseCANResponse = (value) => {
      const format = value.getUint8(0);
      if (format === 'P'.charCodeAt(0)) {
          const responsePayload = new Uint8Array(value.buffer.slice(1));
          Debug.logger(1, 'Parsed CAN response:', responsePayload);
          return responsePayload;
      } else if (format === 'E'.charCodeAt(0)) {
          const errorPayload = new Uint8Array(value.buffer.slice(1));
          Debug.logger(1, 'Parsed Error response:', errorPayload);
          return errorPayload;
      } else {
          throw new Error('Invalid response format');
      }
  };

const startNotificationListener = async (bleService, notificationCallback) => {
    if (!notificationCallback) {
        console.error('A Callback is Required');
        return;
    }
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }
    // console.log('startNotificationListener', bleService);
    try {
        const dataCharacteristicUuid = 'e8012f13-6630-4a43-80e6-5b3986e2f2d7'; // Data characteristic UUID
        const dataCharacteristic = await bleService.getCharacteristic(dataCharacteristicUuid);
        // console.log('startNotificationListener', dataCharacteristic);
        if (!dataCharacteristic) {
            console.error('Data characteristic not found');
            notificationCallback(null, 'Data characteristic not found');
            return;
        }

        // Log characteristic properties
        // console.log('Data characteristic properties:', dataCharacteristic.properties);

        if (!dataCharacteristic.properties.notify) {
            console.error('Data characteristic does not support notifications');
            notificationCallback(null, 'Data characteristic does not support notifications');
            return;
        }

        // console.log('Data characteristic found:', dataCharacteristic);

        // Remove any existing event listener
        if (dataCharacteristic.oncharacteristicvaluechanged) {
            dataCharacteristic.removeEventListener('characteristicvaluechanged', dataCharacteristic.oncharacteristicvaluechanged);
            dataCharacteristic.oncharacteristicvaluechanged = null;
            // console.log('Existing event listener removed');
        }

        const notificationHandler = (event) => {
            const value = new Uint8Array(event.target.value.buffer);

            // Log raw notification data
            logRawCANMessage('DATA REQUEST RECEIVED', value);

            if (notificationCallback) {
                notificationCallback(value);
            }
        };

        dataCharacteristic.addEventListener('characteristicvaluechanged', notificationHandler);
        dataCharacteristic.oncharacteristicvaluechanged = notificationHandler;

        // Increasing delay before starting notifications
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay

        await dataCharacteristic.startNotifications();
        // console.log('Notifications started successfully');
    } catch (error) {
        notificationCallback(null, error);
        console.error('Error starting notification listener:', error);
        if (error instanceof DOMException) {
            console.error('GATT operation failed with code:', error.code);
        }
    }
};

const stopNotificationListener = async (bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const dataCharacteristicUuid = 'e8012f13-6630-4a43-80e6-5b3986e2f2d7'; // Data characteristic UUID
        const dataCharacteristic = await bleService.getCharacteristic(dataCharacteristicUuid);

        // Check if there is an event listener and remove it
        if (dataCharacteristic.oncharacteristicvaluechanged) {
            dataCharacteristic.removeEventListener('characteristicvaluechanged', dataCharacteristic.oncharacteristicvaluechanged);
            dataCharacteristic.oncharacteristicvaluechanged = null;
            // console.log('Event listener removed');
        }

        await dataCharacteristic.stopNotifications();
        // console.log('Notifications stopped');
    } catch (error) {
        console.error('Error stopping notification listener:', error);
    }
};

const getSendPassthroughRW = async (bleService) => {
  if (!bleService) {
    console.error('Bluetooth service is not initialized');
    return null;
  }

  try {
    const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7';
    const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
    if (!passthroughCharacteristic) {
      console.error('Passthrough characteristic not found');
      return null;
    }

    const passthroughFunction = async (readWrite, pin, nodeId, index, subindex, data) => {
      // console.log('passthroughFunction', readWrite, pin, nodeId, index, subindex, data)
      if (!pin) {
        console.error('PIN is required for passthrough function');
        return;
      }

      try {
        const commandPayload = new Uint8Array(17);
        commandPayload.set([...pin.split('').map(char => char.charCodeAt(0))], 0);
        commandPayload[4] = 'P'.charCodeAt(0);
        commandPayload[5] = nodeId & 0xFF;
        commandPayload[6] = (nodeId >> 8) & 0xFF;
        commandPayload[7] = (nodeId >> 16) & 0xFF;
        commandPayload[8] = (nodeId >> 24) & 0xFF;
        commandPayload[10] = index & 0xFF;
        commandPayload[11] = (index >> 8) & 0xFF;
        commandPayload[12] = subindex;

        if (readWrite === 'read') {
          // console.log('passthroughFunction READ', readWrite, pin, nodeId, index, subindex, data);
          commandPayload[9] = 0x40;
          logRawCANMessage('CAN READ REQUEST (PASSTHROUGH)', commandPayload);
        } else if (readWrite === 'write') {
          // console.log('passthroughFunction WRITE', readWrite, pin, nodeId, index, subindex, data);
          commandPayload[9] = 0x2F;
          commandPayload[13] = data & 0xFF;
          commandPayload[14] = (data >> 8) & 0xFF;
          commandPayload[15] = (data >> 16) & 0xFF;
          commandPayload[16] = (data >> 24) & 0xFF;
          // console.log('Constructed write command:', commandPayload);
          logRawCANMessage('CAN WRITE REQUEST (PASSTHROUGH)', commandPayload);
        } else {
          console.error('Invalid readWrite value. Must be "read" or "write".');
          return;
        }

        await passthroughCharacteristic.writeValue(commandPayload);
      } catch (error) {
        console.error('Error in setup and communicate with characteristics:', error);
      }
    };

    return passthroughFunction;
  } catch (error) {
    console.error('Error retrieving characteristic:', error);
    return null;
  }
};


const writePassthroughCharacteristic = async (pin, nodeId, index, subindex, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID
        const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);

        // Construct the read command payload with exactly 17 bytes
        const readCommand = new Uint8Array(17);
        readCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
        readCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
        readCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
        readCommand[6] = (nodeId >> 8) & 0xFF;
        readCommand[7] = (nodeId >> 16) & 0xFF;
        readCommand[8] = (nodeId >> 24) & 0xFF;
        readCommand[9] = 0x40; // Payload Type: Command to read (1 byte)
        readCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
        readCommand[11] = (index >> 8) & 0xFF;
        readCommand[12] = subindex; // Sub Index (1 byte)
        // Remaining 4 bytes are zero-padded for the data payload

        // Log the constructed read command
        logRawCANMessage('PASSTHROUGH CHARACTERISTIC READ', readCommand);

        // Write the command to the characteristic and log the result
        const response = await passthroughCharacteristic.writeValue(readCommand);
    } catch (error) {
        console.error('Error writing passthrough characteristic:', error);
    }
};

const sendPassthroughRead = async (pin, nodeId, index, subindex, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID

        // Get passthrough characteristic
        const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
        if (!passthroughCharacteristic) {
            console.error('Passthrough characteristic not found');
            return;
        }

        // Construct the read command payload with exactly 17 bytes
        const readCommand = new Uint8Array(17);
        readCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
        readCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
        readCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
        readCommand[6] = (nodeId >> 8) & 0xFF;
        readCommand[7] = (nodeId >> 16) & 0xFF;
        readCommand[8] = (nodeId >> 24) & 0xFF;
        readCommand[9] = 0x40; // Payload Type: Command to read (1 byte)
        readCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
        readCommand[11] = (index >> 8) & 0xFF;
        readCommand[12] = subindex; // Sub Index (1 byte)
        // Remaining 4 bytes are zero-padded for the data payload

        // Log raw read command
        logRawCANMessage('CAN READ REQUEST', readCommand);

        // Write the command to the characteristic
        await passthroughCharacteristic.writeValue(readCommand);
        console.log('Write command sent successfully');
    } catch (error) {
        console.error('Error in setup and communicate with characteristics:', error);
    }
};

const sendPassthroughWrite = async (pin, nodeId, index, subindex, data, bleService, size) => {
    console.log('sendPassthroughWrite', pin, nodeId, index, subindex, data, bleService);
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }

    try {
        const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID

        // Get passthrough characteristic
        const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
        if (!passthroughCharacteristic) {
            console.error('Passthrough characteristic not found');
            return;
        }

        // Ensure the data is a Uint8Array and matches the specified size
        let dataArray;
        if (typeof data === 'number') {
            dataArray = new Uint8Array(size);
            for (let i = 0; i < size; i++) {
                dataArray[i] = (data >> (8 * i)) & 0xFF;
            }
        } else if (data instanceof Uint8Array) {
            dataArray = data;
        }

        // Determine the payload type based on the size
        let payloadType = 0x2F;
        if (size == 2) {
                payloadType = 0x2B; // 2 bytes write
        }
        if (size == 3) {
                payloadType = 0x27; // 3 bytes write
        }
        if (size == 4) {
                payloadType = 0x23; // 4 bytes write
        }

        // Construct the write command payload with exactly 17 bytes
        const writeCommand = new Uint8Array(17);
        writeCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
        writeCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
        writeCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
        writeCommand[6] = (nodeId >> 8) & 0xFF;
        writeCommand[7] = (nodeId >> 16) & 0xFF;
        writeCommand[8] = (nodeId >> 24) & 0xFF;
        writeCommand[9] = payloadType; // Payload Type based on data length
        writeCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
        writeCommand[11] = (index >> 8) & 0xFF;
        writeCommand[12] = subindex; // Sub Index (1 byte)
        writeCommand.set(dataArray, 13); // Set the data bytes, with appropriate length

        // Log raw write command
        logRawCANMessage('CAN WRITE REQUEST', writeCommand);

        // Write the actual command
        await passthroughCharacteristic.writeValue(writeCommand);
        console.log('Write command sent successfully');
    } catch (error) {
        console.error('Error in setup and communicate with characteristics:', error);
    }
};

const readPassthroughValue = async (pin, nodeId, index, subindex, bleService) => {
    if (!bleService) {
        console.error('Bluetooth service is not initialized');
        return;
    }
    console.log('readPassthroughValue', pin, nodeId, index, subindex)
    return new Promise(async (resolve, reject) => {
        let retries = 0;
        let timeoutId;

        const sendReadCommand = async () => {
            try {
                const passthroughCharacteristicUuid = 'e8012f15-6630-4a43-80e6-5b3986e2f2d7'; // Passthrough characteristic UUID
                const passthroughCharacteristic = await bleService.getCharacteristic(passthroughCharacteristicUuid);
                if (!passthroughCharacteristic) {
                    console.error('Passthrough characteristic not found');
                    reject('Passthrough characteristic not found');
                    return;
                }

                // Construct the read command payload with exactly 17 bytes
                const readCommand = new Uint8Array(17);
                readCommand.set([...pin.split('').map(char => char.charCodeAt(0))], 0); // PIN (4 bytes)
                readCommand[4] = 'P'.charCodeAt(0); // Message Type (1 byte)
                readCommand[5] = nodeId & 0xFF; // Node ID (4 bytes, little endian)
                readCommand[6] = (nodeId >> 8) & 0xFF;
                readCommand[7] = (nodeId >> 16) & 0xFF;
                readCommand[8] = (nodeId >> 24) & 0xFF;
                readCommand[9] = 0x40; // Payload Type: Command to read (1 byte)
                readCommand[10] = index & 0xFF; // Address Bytes (2 bytes, little endian)
                readCommand[11] = (index >> 8) & 0xFF;
                readCommand[12] = subindex; // Sub Index (1 byte)
                // Remaining 4 bytes are zero-padded for the data payload

                // Log the read command
                logRawCANMessage('READ PASSTHROUGH VALUE', readCommand);

                // Write the command to the characteristic
                await passthroughCharacteristic.writeValue(readCommand);
                console.log('Write command sent successfully');
            } catch (error) {
                clearTimeout(timeoutId);
                stopNotificationListener(bleService);
                console.error('Error in sending read command:', error);
                reject(error);
            }
        };

        const notificationCallback = (event) => {
            const value = new Uint8Array(event.buffer);

            // Log received response
            logRawCANMessage('READ PASSTHROUGH RESPONSE', value);

            const canHeader = value.slice(1, 5);
            const canDataBytes = value.slice(5, 9);

            const res_data = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);
            const res_index = (canHeader[2] << 8) | canHeader[1];
            const res_subIndex = canHeader[3];

            console.log('notification read passthrough', index, res_index, subindex, res_subIndex, res_data);
            
            if (index === res_index && subindex === res_subIndex) {
                clearTimeout(timeoutId);
                stopNotificationListener(bleService);
                resolve(res_data);
            }
        };

        await startNotificationListener(bleService, notificationCallback);

        const attemptReadCommand = async () => {
            if (retries >= 3) {
                console.error('Maximum retries reached, no response received');
                stopNotificationListener(bleService);
                resolve(false);
                return;
            }

            await sendReadCommand();
            retries += 1;

            timeoutId = setTimeout(attemptReadCommand, 500); // Retry after 200ms
        };

        attemptReadCommand();
    });
};


//methods for retrieving characteristics while connected
  const getDeviceInformation = async (device, service, setLoadingScreen, setBleConnectionError) => {
    if (debugBLE) {
      setLoadingScreen(false);
      return "TESTBIKE001";
    }

    if (!service || !device?.gatt.connected) {
      Debug.logger(2, 'Device not connected or service unavailable');
      setBleConnectionError(true);
      setLoadingScreen(false);
      return;
    }

    try {
      const serialNumberCharacteristic = await service.getCharacteristic(Protocol.deviceInformation.characteristics.serialNumber);
      const serialNumber = await serialNumberCharacteristic.readValue();
      const serialNumberDec = dataViewToAsciiString(serialNumber);
      Debug.logger(2, "Serial Number of Controller", serialNumberDec);
      setBikeSerial(serialNumberDec);
      return serialNumberDec;
      // try {
      //   const firmwareCharacteristic = await service.getCharacteristic(Protocol.deviceInformation.characteristics.firmwareRevision);
      //   const firmwareNumber = await firmwareCharacteristic.readValue();
      //   Debug.logger(2, "Firmware Number of Controller", firmwareNumber);
      // } catch (error) {
      //   Debug.logger(1, 'failed to get firmware');
      // }

    } catch (error) {
      Debug.logger(2, 'Error fetching device information:', error);
      setBleConnectionError(true);
      setLoadingScreen(false);
    }
  };

  const getFirmwareVersion = async () => {
      if (!deviceInformationService) {
          Debug.logger(2, 'Device Information Service not available');
          return null;
      }

      try {
          const firmwareRevisionCharacteristicUuid = 0x2A26;
          const characteristic = await deviceInformationService.getCharacteristic(firmwareRevisionCharacteristicUuid);
          const firmwareData = await characteristic.readValue();
          return formatFirmwareVersion(firmwareData);
      } catch (error) {
          Debug.logger(2, 'Error fetching firmware version:', error);
          return null;
      }
  };

  const debugClearErrorFlag = () => {
    return new Promise((resolve) => {
      Debug.logger(3, 'debugClearError');
      if (debugBLE) {
        setDebugClearError(true);
        Debug.logger(3, 'debugBLE after true setDebugClearError', debugClearError);
        resolve(true);
      } else {
        Debug.logger(3, 'debugBLE after false setDebugClearError', debugClearError);
        resolve(false);
      }
    });
  };

  const getErrorCodeByBLE = async (setBikeError, returnEmpty) => {
    console.log('getErrorCodeByBLE')
    if (debugBLE) {
      console.log('getErrorCodeByBLE debugBLE')
      if (!returnEmpty) {
        setBikeCurrentError([{
          code: '4',
          description: 'Low Voltage',
          timestamp: new Date().toISOString(),
        }])
        return [{
          code: '4',
          description: 'Low Voltage',
          timestamp: new Date().toISOString(),
        }];        
      } else {
        setBikeCurrentError([])
        return [];
      }
    }

    if (!primaryService) {
      console.log('getErrorCodeByBLE !primaryService')
      Debug.logger(2, 'Primary service not available');
      return null;
    }
    Debug.logger(1, 'primary service found');
    try {
      const errorCharacteristic = await primaryService.getCharacteristic(Protocol.primaryService.characteristics.error);
      const errorValue = await errorCharacteristic.readValue();
      console.log('getErrorCodeByBLE TRY', errorValue)
      const errors = readErrorCodeCharacteristic(errorValue);
      console.log('BLE ERROR REAR', errorValue, errors);
      Debug.logger(2, 'Error code fetched dataview:', errorValue, errors);
      setBikeCurrentError(errors)

      return errors;
    } catch (error) {
      console.log('getErrorCodeByBLE CATCH', error)
      Debug.logger(2, 'Error fetching error code:', error);
      return null; // Handle error appropriately
    }
  };


//methods for updating current state
  const preFlightComplete = () => {
    setIsPreflightChecked(true);
  };

  const resetPreFlightComplete = () => {
    setIsPreflightChecked(false);
  };

  const checkBluetoothConnection = () => {
    return isConnected;
  };

//PLACEHOLDERS for future functionality
  const checkLTEConnection = async () => {
    // Implement actual LTE connectivity check
    return true; // Placeholder
  };

  const checkGPSFunction = async () => {
    // Implement actual GPS functionality check
    return true; // Placeholder
  };

  const checkBatteryVoltage = async () => {
    // Implement actual battery voltage check
    return 40; // Placeholder
  };

    const updateControllerSettings = async (pin, updates, bleService) => {
        console.log('updateControllerSettings', updates);
        if (!bleService) {
            console.error('Bluetooth service is not initialized');
            return;
        }

        try {
            // Enter Configuration Mode with 2-byte command
            await sendPassthroughWrite(pin, 0x00000601, 0x2014, 0x00, new Uint8Array([0xA3, 0xD5]), bleService, 2);
            console.log('Sent [0xA3, 0xD5] to enter configuration mode');
            console.log('Controller entered configuration mode');
            await new Promise(resolve => setTimeout(resolve, 500));

            const convertToUint8Array = (value, size) => {
                const dataArray = new Uint8Array(size);
                for (let i = 0; i < size; i++) {
                    dataArray[i] = (value >> (8 * i)) & 0xFF;
                }
                return dataArray;
            };

            // Apply updates
            for (const update of updates) {
                const { index, sub_index, newValue, size } = update;

                console.log(`Updated index ${index}, sub-index ${sub_index}, size ${size}, newValue ${newValue}`, convertToUint8Array(newValue, size));
                await sendPassthroughWrite(pin, 0x00000601, index, sub_index, convertToUint8Array(newValue, size), bleService, size);
                await new Promise(resolve => setTimeout(resolve, 250));
            }

            // Exit Configuration Mode with 2-byte command
            await sendPassthroughWrite(pin, 0x00000601, 0x2014, 0x00, new Uint8Array([0xE5, 0xC2]), bleService, 2);
            console.log('Sent [0xE5, 0xC2] to exit configuration mode');
            console.log('Controller exited configuration mode');
        } catch (error) {
            console.error('Error updating controller settings:', error);
        }
    };


    // FIRMWARE UPDATE
const performFirmwareUpdate = async (primaryService, pin, updateFile, callbackFunction, logCallback) => {
  const dfuManager = new DFUManager();

  try {
    if (!primaryService || !pin || !updateFile) {
      console.error('Missing required parameters for firmware update.');
      callbackFunction(0, 'Missing required parameters');
      return;
    }

    console.log('Connecting to device...');
    await device.gatt.connect();
    console.log('Device connected:', device);

    const firmwareService = await server.getPrimaryService(Protocol.otaService.service);
    console.log('Firmware service retrieved:', firmwareService);

    await dfuManager.startUpdate(
      firmwareService,
      primaryService,
      pin,
      updateFile,
      // Progress callback
      (progress) => callbackFunction(progress, `Update progress: ${progress}%`),
      // Success callback
      () => callbackFunction(100, 'Firmware update complete.'),
      // Error callback
      (error) => {
        console.error('Firmware update error:', error);
        callbackFunction(0, error);
      },
      logCallback
    );

  } catch (error) {
    console.error('Firmware update failed:', error);
    callbackFunction(0, error.message);
    await dfuManager.cleanup();
  }
};

const getDeviceVersionInfo = async () => {
  if (debugBLE) {
    return {
      serialNumber: "TESTBIKE001",
      firmwareRevision: "01.02.0001 01.02.0005 01.01.000B",
      hardwareRevision: "v01.02"
    };
  }

  if (!deviceInformationService) {
    Debug.logger(2, 'Device Information Service not available');
    return null;
  }

  try {
    // Get Serial Number
    const serialNumberCharacteristic = await deviceInformationService.getCharacteristic(Protocol.deviceInformation.characteristics.serialNumber);
    const serialNumberValue = await serialNumberCharacteristic.readValue();
    const serialNumber = dataViewToAsciiString(serialNumberValue);

    // Get Firmware Revision
    // const firmwareRevisionCharacteristic = await deviceInformationService.getCharacteristic(Protocol.deviceInformation.characteristics.firmwareRevision);
    // const firmwareValue = await firmwareRevisionCharacteristic.readValue();
    const firmwareRevision = "firmware version"; //new TextDecoder().decode(firmwareValue);

    // Get Hardware Revision
    // const hardwareRevisionCharacteristic = await deviceInformationService.getCharacteristic(Protocol.deviceInformation.characteristics.hardwareRevision);
    // const hardwareValue = await hardwareRevisionCharacteristic.readValue();
    const hardwareRevision = "firmware version"; //new TextDecoder().decode(hardwareValue);

    return {
      serialNumber,
      firmwareRevision,
      hardwareRevision
    };
  } catch (error) {
    Debug.logger(2, 'Error fetching device version information:', error);
    return null;
  }
};


  return {
    isConnected,
    isPreflightChecked,
    preFlightComplete,
    resetPreFlightComplete,
    debugClearError,
    connectToDevice,
    disconnectFromDevice,
    debugClearErrorFlag,
    checkBluetoothConnection,
    getDebugState,
    bikeSerial,
    getErrorCodeByBLE,
    getFirmwareVersion,
    verifyPin,
    primaryService,
    startNotificationListener,
    stopNotificationListener,
    writePassthroughCharacteristic,
    readPassthroughValue,
    sendPassthroughRead,
    sendPassthroughWrite,
    getSendPassthroughRW,
    performFirmwareUpdate,
    updateControllerSettings,
    getDeviceVersionInfo
  };
};

export default useBluetooth;