import { useMemo } from "react";
import internalCAN from "./../common/internalCanProtocol.json";
import publicCan from "./../common/publicCanProtocol.json";
import bmsCan from "./../common/bmsCanProtocol.json";

function formatParameterName(paramName) {
    // Remove CO_PARAM_ prefix
    //console.log('paramName', paramName)
    let formatted = paramName.replace('CO_PARAM_', '');
    
    // Split by underscore, capitalize first letter of each word, join with space
    return formatted
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
        .join(' ');
}

function extractCOParams(protocols) {
    const result = {};

    protocols.forEach(protocol => {
        Object.keys(protocol).forEach(section => {
            if (section === "protocol") return; // Skip metadata about the protocol itself

            const items = protocol[section];
            Object.keys(items).forEach(item => {
                const itemIndex = items[item]?.CANOpen_Index;

                if (!itemIndex || !items[item]?.Parameters) return;

                const parameters = items[item]?.Parameters;

                Object.keys(parameters).forEach(param => {
                    const paramDetails = parameters[param];

                    result[param] = {
                        index: itemIndex,
                        sub_index: paramDetails?.Subindex,
                        name: param,
                        type: paramDetails?.Type,
                        access: paramDetails?.Access,
                        description: paramDetails?.Description,
                        validOptions: paramDetails?.Valid_Options || null,
                        validRange: paramDetails?.Valid_Range || null,
                        persistence: paramDetails?.Persistence || null,
                        unit: paramDetails?.Unit || null,
                        notes: paramDetails?.Notes || null,
                    };
                });
            });
        });
    });

    return result;
}

function processMapping(mergedProtocolMapping, mappingJSON) {
    const processedMapping = JSON.parse(JSON.stringify(mappingJSON)); // Deep clone to avoid mutation

    const transformVariable = (variableData) => {
        if (!variableData) return null;

        const {
            subindex,
            name,
            description,
            notes,
            type,
            unit,
            validRange,
            validOptions,
            ...rest
        } = variableData;

        const transformedVariable = {
            sub_index: subindex,
            input_title: name,
            input_tool_tip: `${description}${notes ? ` ${notes}` : ""}`,
            size: type?.includes("uint8") ? 1 : type?.includes("uint16") ? 2 : type?.includes("uint32") ? 4 : null,
            input_unit: unit || "",
            ...rest
        };

        // Handle validRange
        if (validRange && validRange.min !== undefined && validRange.max !== undefined) {
            transformedVariable.input_type = "text_input";
            transformedVariable.input_value_range = [validRange.min, validRange.max];
        }

        // Handle validOptions
        if (validOptions && Array.isArray(validOptions)) {
            transformedVariable.input_type = "combo_input";
            transformedVariable.input_value_options = validOptions.map(option => ({
                key: option.description,
                value: option.value
            }));
        }

        return transformedVariable;
    };

    const replaceVariables = (variables) => {
        const variableMapping = {};
        variables.forEach(variable => {
            if (mergedProtocolMapping[variable]) {
                variableMapping[variable] = transformVariable(mergedProtocolMapping[variable]);
            } else {
                console.warn(`Variable ${variable} not found in mergedProtocolMapping`);
                variableMapping[variable] = {
                    input_title: variable,
                    error: "Mapping not found"
                }; // Fallback in case mapping doesn't exist
            }
        });
        return variableMapping;
    };

    Object.keys(processedMapping).forEach(section => {
        const categories = processedMapping[section];
        Object.keys(categories).forEach(category => {
            const variables = categories[category].variables;
            if (variables && Array.isArray(variables)) {
                categories[category].variables = replaceVariables(variables);
            }
        });
    });

    return processedMapping;
}

// Helper function to format parameter data to the required structure
function formatParamData(paramData, paramName) {
    if (!paramData) return null;
    
    return {
        Index: typeof paramData.index === 'string' ? paramData.index : `0x${paramData.index.toString(16).padStart(4, '0')}`,
        Subindex: paramData.sub_index,
        Access: paramData.access,
        Type: paramData.type,
        Unit: paramData.unit,
        Description: paramData.description,
        Valid_Range: paramData.validRange,
        Persistence: paramData.persistence,
        Notes: paramData.notes,
        Name: formatParameterName(paramName)
    };
}

export const useCAN = () => {
    const mergedProtocolMapping = useMemo(() => 
        extractCOParams([publicCan, internalCAN, bmsCan]), 
        []
    );

    const publicBmsProtocolMapping = useMemo(() =>
        extractCOParams([publicCan, bmsCan]),
        []
    );

    const getConfigMapping = (mappingJSON) => {
        const processedMapping = processMapping(mergedProtocolMapping, mappingJSON);
        return processedMapping;
    };

    const getAllProtocols = () => {
        return mergedProtocolMapping;
    };

    const getProtocolsSeparately = () => {
        return {
            public: extractCOParams([publicCan]),
            internal: extractCOParams([internalCAN]),
            bms: extractCOParams([bmsCan])
        };
    };

    // New function to get protocol info by parameter name
    const getProtocolByParam = (paramName) => {
        const paramData = mergedProtocolMapping[paramName];
        return paramData ? formatParamData(paramData, paramName) : null;
    };

    // New function to get all real-time parameters
    const getRealTimeParams = () => {
        const realTimeParams = Object.entries(mergedProtocolMapping)
            .filter(([paramName, paramData]) => paramData.persistence === "Real-time")
            .map(([paramName, paramData]) => formatParamData(paramData, paramName));

        return realTimeParams.length === 1 ? realTimeParams[0] : realTimeParams;
    };

    const getRealTimePublicParams = () => {
        const realTimeParams = Object.entries(publicBmsProtocolMapping)
            .filter(([paramName, paramData]) => paramData.persistence === "Real-time")
            .map(([paramName, paramData]) => formatParamData(paramData, paramName));

        return realTimeParams.length === 1 ? realTimeParams[0] : realTimeParams;
    };

    return { 
        getConfigMapping, 
        getAllProtocols, 
        getProtocolsSeparately,
        getProtocolByParam,
        getRealTimeParams,
        getRealTimePublicParams
    };
};