import React, { useEffect, useState } from "react";
import { Routes, Route, Link, Navigate, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import ForgotPassword from "./components/forgotPassword.component";
import ResetPassword from "./components/resetPassword.component";
import Register from "./components/register.component";
import Modules from "./components/modules.component";
import Resources from "./components/resources.component";
import BluetoothPreflight from './components/bluetooth-preflight.component';
import useBluetooth from "./hooks/useBluetooth.hook";
import useLoadingScreen from "./hooks/useLoadingScreen.hook";
import { useDebug } from './hooks/useDebugContext';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Debug from "./services/debug.js";
import RestAPIService from "./services/rest-api.service";
import Dropdown from 'react-bootstrap/Dropdown';
import HelpComponent from './components/help.component';
import HelpPage from './components/help-page.component';
import DebugToggle from './components/debug-toggle.component';
import Logo from './components/logo.component';
//user management componnets
import UsersComponent from './components/users/users.component';
import UserDetailComponent from './components/users/userDetail.component'; 
import StoreDetailComponent from './components/users/storeDetail.component';
//fleet management components
import FleetsComponent from './components/fleet/fleet.component';
//analytics managemet components
import AnalyticsComponent from './components/analytics/analytics.component';
//manufacturing components
import ManufacturingComponent from './components/manufacturing/manufacturing.component';
//diagnostics components
import DiagnosticsComponent from './components/diagnostics/diagnostics.component';
import RunRemoteDiagnostics from './components/diagnostics/RunRemoteDiagnostics.component';
//configuration components
import ConfigurationComponent from './components/configuration/configuration.component';
//Developer Tools components
import DevToolsComponent from './components/devTools/devTools.component';
//Documentation Tools components
import DocsComponent from './components/docs/docs.component';
//less
import injectLessStyles from './whiteLabel/injectLessStyles.component';

function App() {
  //for debug only 
  const { debugBLE, setDebugBLE } = useDebug();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentBikeError, setCurrentBikeError] = useState(undefined);
  const [userGroup, setUserGroup] = useState("");
  const [isHomePage, setIsHomePage] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentSolution, setCurrentSolution] = useState(undefined);
  const {
    isConnected,
    isPreflightChecked,
    preFlightComplete,
    resetPreFlightComplete,
    connectToDevice,
    disconnectFromDevice,
    checkBluetoothConnection,
    checkLTEConnection,
    checkGPSFunction,
    checkBatteryVoltage,
    getErrorCodeByBLE,
    bikeSerial,
    getBikeBrand,
    getBikeModel,
    getFirmwareVersion,
    getDebugState,
    debugClearErrorFlag,
    debugClearError,
    verifyPin,
    primaryService,
    startNotificationListener,
    stopNotificationListener,
    writePassthroughCharacteristic,
    readPassthroughValue,
    sendPassthroughRead,
    sendPassthroughWrite,
    getSendPassthroughRW,
    performFirmwareUpdate,
    updateControllerSettings,
    getDeviceVersionInfo,
  } = useBluetooth(debugBLE);
  const {
    isLoading,
    setLoadingScreen,
    loadingScreenError,
    resetLoadingScreenError,
    errorMsg,
  } = useLoadingScreen();

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };


  useEffect(() => {
    const i18nextLng = localStorage.getItem('i18nextLng');
    if (i18nextLng === 'fr') {
      i18n.changeLanguage('fr');
    }

    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      const currentUserGroup = AuthService.getUserGroup();
      setUserGroup(currentUserGroup);
      injectLessStyles(currentUserGroup);
    }
  }, [i18n]);

  useEffect(() => {
    injectLessStyles(userGroup);
  }, [userGroup])

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      localStorage.setItem('i18nextLng', lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);


  async function disconnectAllDevices() {
    if (!navigator.bluetooth) {
      console.log('Web Bluetooth API is not available');
      return;
    }

    console.log('bluetooth test', isConnected, disconnectFromDevice);

    if (isConnected) {
      disconnectFromDevice();
    }


    // try {
    //   const devices = await navigator.bluetooth.getDevices();
    //   const disconnectPromises = devices
    //     .filter(device => device.gatt.connected)
    //     .map(device => device.gatt.disconnect());
      
    //   await Promise.all(disconnectPromises);
    //   console.log('All devices disconnected');
    // } catch (error) {
    //   console.error('Error disconnecting devices:', error);
    // }
  }

  const handleLogoNavigation = async (e) => {
    e.preventDefault();
    await disconnectAllDevices();
    navigate('/modules');
  };

  useEffect(() => {
    let ServerintervalID;

    const checkServerStatus = async () => {
      Debug.logger('1', "Check server status");
      let status = await RestAPIService.checkServer();
      if (!status) {
        setLoadingScreen(true);
        Debug.logger('2', "Server is down");
        loadingScreenError("Server is currently down, please refresh or try again later");

        if (!ServerintervalID) {
          ServerintervalID = setInterval(function () {
            Debug.logger('1', "Rechecking server status");
            checkServerStatus();
          }, 10000);
        }
      } else {
        if (ServerintervalID) {
          setLoadingScreen(false);
          Debug.logger('1', "Server back up");
          loadingScreenError("");
          clearInterval(ServerintervalID);
        }
      }
    }
    //checkServerStatus();

    let wordpressIntervalID;

    const checkWordpressStatus = async () => {
      Debug.logger('1', "Check server status");
      let status = await AuthService.checkWordpressStatus();
      if (!status) {
        setLoadingScreen(true);
        Debug.logger('2', "Server is down");
        loadingScreenError("Server is currently down, please refresh or try again later");

        if (!wordpressIntervalID) {
          wordpressIntervalID = setInterval(function () {
            Debug.logger('1', "Rechecking server status");
            checkWordpressStatus();
          }, 10000);
        }
      } else {
        if (wordpressIntervalID) {
          setLoadingScreen(false);
          Debug.logger('1', "Server back up");
          loadingScreenError("");
          clearInterval(wordpressIntervalID);
        }
      }
    }
    //checkWordpressStatus();

    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      const currentUserGroup = AuthService.getUserGroup();
      setUserGroup(currentUserGroup);

      //injecting custom client colours
      injectLessStyles(currentUserGroup);
    }
  }, [isConnected, isPreflightChecked]);

  useEffect(() => {
    Debug.logger(1, 'user group updated', userGroup, currentUser)
    if (currentUser && Array.isArray(currentUser.roles) && currentUser.roles.includes("diagnostics-admin")) {
      setIsAdmin(true);
    }
  }, [userGroup, currentUser]);

  useEffect(() => {
    if (location.pathname.startsWith("/admin")) {
      setIsHomePage(false)
    } else {
      setIsHomePage(true)
    }
  }, [location]);

  const switchFrench = () => {
    i18n.changeLanguage("fr");
    AuthService.updateUserLocale("fr");
  }

  const switchEnglish = () => {
    i18n.changeLanguage("en");
    AuthService.updateUserLocale("en_US");
  }

  const path = location.pathname;
  let isWhiteGreyClass = path.startsWith('/manufaturing') || path.startsWith('/users') || path.startsWith('/fleet') || path.startsWith('/analytics') || path.startsWith('/diagnostics') || path.startsWith('/configuration');
  let isGreyClass = path.startsWith('/help') || path.startsWith('/configuration/pin-entry') || path.startsWith('/dev-tools/pin-entry');

  return (
    <div className={`main ${userGroup} ${debugBLE ? "debug" : ""}`}>
      {isLoading() && (
        <div className="loading-screen">
          {errorMsg && (
            <span className="loading-screen-error">{errorMsg}</span>
          )}
        </div>
      )}
      <HelpComponent userGroup={userGroup} />
      <nav className="navbar top-nav">
        {currentUser ? (
          <span className="navbar-brand">
            <span className="img-container">
              <Link to="/modules" onClick={handleLogoNavigation}>
                <img src="/imgs/ftex-logo-color.png" className="FTEX Diagnostics Tool" />
                {userGroup && userGroup.toLowerCase() !== 'ftex' && userGroup.toLowerCase() !== 'ftex-client' && userGroup.toLowerCase() !== 'admin' && <span>//</span>}
                <Logo userGroup={userGroup} noDefault={true}/>
              </Link>
            </span>
          </span>
        ) : (
          <span className="navbar-brand">
            <span className="img-container">
              <img src="/imgs/ftex-logo-color.png" className="FTEX Diagnostics Tool" />
              {userGroup && userGroup.toLowerCase() !== 'ftex' && userGroup.toLowerCase() !== 'admin' && <span>//</span>}
              <Logo userGroup={userGroup} />
            </span>
          </span>
        )}
        <ul className="lang-menu">
          {currentUser && (
            <>
              <li className="nav-item">
                <DebugToggle/>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  {t('Logout')}
                  <BoxArrowRight/>
                </a>
              </li>
            </>
          )}
          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-language">
                {t('Language')}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="black" className="bi bi-translate" viewBox="0 0 24 24">
                  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z"/>
                  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31"/>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={switchFrench}>Français</Dropdown.Item>
                <Dropdown.Item href="#" onClick={switchEnglish}>English</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </nav>
      <div className={`content-container ${isWhiteGreyClass ? 'white-grey' : ''} ${isGreyClass ? 'grey' : ''}`}>
        <Routes>
          <Route
            path="/"
            element={(
              <Login
                setLoadingScreen={setLoadingScreen}
              />
            )}
          />
          <Route
            path="/login"
            element={(
              <Login
                setLoadingScreen={setLoadingScreen}
              />
            )}
          />
          <Route
            path="/modules"
            element={(
              <Modules
                userGroup={userGroup}
                setUserGroup={setUserGroup}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
              />
            )}
          />
          <Route
            path="/forgotPassword"
            element={(
              <ForgotPassword
                setLoadingScreen={setLoadingScreen}
              />
            )}
          />
          <Route
            path="/resetPassword"
            element={(
              <ResetPassword
                setLoadingScreen={setLoadingScreen}
              />
            )}
          />
          <Route path="/resources" element={<Resources />} />
          {/*DIAGNOSTICS ROUTES*/}
          <Route 
            path="/diagnostics/*"
            element={
              <DiagnosticsComponent 
                userGroup={userGroup}
                isConnected={isConnected}
                setUserGroup={setUserGroup}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                connectToDevice={connectToDevice}
                setLoadingScreen={setLoadingScreen}
                disconnectFromDevice={disconnectFromDevice}
                getErrorCodeByBLE={getErrorCodeByBLE}
                bikeSerial={bikeSerial}
                debugClearErrorFlag={debugClearErrorFlag}
                debugClearError={debugClearError}
                stopNotificationListener={stopNotificationListener}
                startNotificationListener={startNotificationListener}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                primaryService={primaryService}
                verifyPin={verifyPin}
                getSendPassthroughRW={getSendPassthroughRW}
                getDeviceVersionInfo={getDeviceVersionInfo}
              />
            }
          />
          <Route 
            path="/live"
            element={
              <RunRemoteDiagnostics
                userGroup={userGroup}
                isConnected={isConnected}
                setUserGroup={setUserGroup}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                connectToDevice={connectToDevice}
                setLoadingScreen={setLoadingScreen}
                disconnectFromDevice={disconnectFromDevice}
                getErrorCodeByBLE={getErrorCodeByBLE}
                bikeSerial={bikeSerial}
                debugClearErrorFlag={debugClearErrorFlag}
                debugClearError={debugClearError}
                stopNotificationListener={stopNotificationListener}
                startNotificationListener={startNotificationListener}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                primaryService={primaryService}
                verifyPin={verifyPin}
                getSendPassthroughRW={getSendPassthroughRW}
              />
            }
          />
          {/*USER MANAGEMENT ROUTES*/}
          <Route 
            path="/users/*"
            element={
              <UsersComponent userGroup={userGroup} />
            }
          />
          {/*FLEET MANAGEMENT ROUTES*/}
          <Route 
            path="/fleet/*"
            element={
              <FleetsComponent userGroup={userGroup} />
            }
          />
          {/*ANALYTICS ROUTES*/}
          <Route 
            path="/analytics/*"
            element={
              <AnalyticsComponent userGroup={userGroup} />
            }
          />
          {/* MANUFACTURING ROUTES */}
          <Route 
          path="/manufacturing/*"
          element={<ManufacturingComponent userGroup={userGroup} />}
          />
          {/*CONFIGURATION  ROUTES*/}
          <Route 
            path="/configuration/*"
            element={
              <ConfigurationComponent 
                userGroup={userGroup}     
                verifyPin={verifyPin}
                primaryService={primaryService}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                disconnectFromDevice={disconnectFromDevice}
                readPassthroughValue={readPassthroughValue}
                connectToDevice={connectToDevice}
                isConnected={isConnected}
                bikeSerial={bikeSerial}
                performFirmwareUpdate={performFirmwareUpdate}
                updateControllerSettings={updateControllerSettings}
                startNotificationListener={startNotificationListener}
                stopNotificationListener={stopNotificationListener}
                getSendPassthroughRW={getSendPassthroughRW}
                updateControllerSettings={updateControllerSettings}
                getDeviceVersionInfo={getDeviceVersionInfo}
              />
            }
          />
          {/*DEVTOOLS  ROUTES*/}
          <Route 
            path="/dev-tools/*"
            element={
              <DevToolsComponent 
                userGroup={userGroup}     
                verifyPin={verifyPin}
                primaryService={primaryService}
                writePassthroughCharacteristic={writePassthroughCharacteristic}
                disconnectFromDevice={disconnectFromDevice}
                readPassthroughValue={readPassthroughValue}
                connectToDevice={connectToDevice}
                isConnected={isConnected}
                bikeSerial={bikeSerial}
                performFirmwareUpdate={performFirmwareUpdate}
                updateControllerSettings={updateControllerSettings}
                startNotificationListener={startNotificationListener}
                stopNotificationListener={stopNotificationListener}
                getSendPassthroughRW={getSendPassthroughRW}
                updateControllerSettings={updateControllerSettings}
                getDeviceVersionInfo={getDeviceVersionInfo}
              />
            }
          />
          {/*DOCS  ROUTES*/}
          <Route 
            path="/docs/*"
            element={
              <DocsComponent 
              />
            }
          />
          {/*HELP ROUTES*/}
          <Route 
            path="/help"
            element={
              <HelpPage userGroup={userGroup} />
            }
          />
          {/*DEFAULT*/}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;